export function getEmptyPeriod(dates) {
  let period = "";
  if (dates.length === 1) {
    period = formatLongDate(dates[0]);
  } else {
    period =
      formatLongDate(dates[0]) +
      " - " +
      formatLongDate(dates[dates.length - 1]);
  }

  return period;
}

export function getDateBetween(start, end) {
  var dates = [];
  for (
    var currentDate = getDatePart(start);
    currentDate <= end;
    currentDate.setDate(currentDate.getDate() + 1)
  ) {
    dates.push(new Date(currentDate));
  }
  return dates;
}

export function convertDate(myDate) {
  let day = myDate.substring(0, 2);
  let month = myDate.substring(2, 4);
  let year = myDate.substring(4, 8);

  let theDate = new Date(year, month - 1, day, 0, 0, 0, 0);
  return theDate;
}

export function convertDayJSDate(myDate) {
  let day = myDate.substring(0, 2);
  let month = myDate.substring(2, 4);
  let year = myDate.substring(4, 8);
  return `${year}-${month}-${day}`;
}

export function convertDateTime(myDate) {
  let day = myDate.substring(0, 2);
  let month = myDate.substring(2, 4);
  let year = myDate.substring(4, 8);
  let hour = myDate.substring(8, 10);
  let minute = myDate.substring(10, 12);

  let theDate = new Date(year, month - 1, day, hour, minute, 0, 0);
  return theDate;
}

export function convertTime(myDate) {
  let hour = myDate.substring(8, 10);
  let minute = myDate.substring(10, 12);
  return hour + ":" + minute;
}

export function convertTime12Hours(myDate) {
  let hour = parseInt(myDate.substring(8, 10), 10);
  let minute = myDate.substring(10, 12);
  let period = "AM";

  // Convert to 12-hour format
  if (hour >= 12) {
    period = "PM";
    if (hour > 12) {
      hour -= 12;
    }
  } else if (hour === 0) {
    hour = 12;
  }

  // Format hour with a single leading zero if it's between 1 and 9
  hour = hour < 10 ? `0${hour}` : hour;
  return hour + ":" + minute + " " + period;
}

export function formatDate(myDate) {
  let yyyy = myDate.getFullYear();
  let mm = myDate.getMonth() + 1;
  let dd = myDate.getDate();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  let formattedDate = dd.toString() + mm.toString() + yyyy.toString();
  return formattedDate;
}

export function getDatePart(myDate) {
  return new Date(myDate.toDateString());
}

export function isMorning(myTime) {
  let hour = myTime.substring(0, 2);
  return parseInt(hour) < 12;
}

export function formatLongDate(myDate) {
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
  };
  return myDate.toLocaleDateString("en-AU", options).replace(",", "");
}

export function isValidDate(date) {
  if (!/^\d\d\/\d\d\/\d\d\d\d$/.test(date)) {
    return false;
  }
  let dateformat = /^(0?[1-9]|[1-2][0-9]|3[01])[\/](0?[1-9]|1[0-2])/;
  if (date.match(dateformat)) {
    let operator = date.split("/");

    let datepart = [];
    if (operator.length > 1) {
      datepart = date.split("/");
    }
    let day = parseInt(datepart[0]);
    let month = parseInt(datepart[1]);
    let year = parseInt(datepart[2]);
    let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (month == 1 || month > 2) {
      if (day > ListofDays[month - 1]) {
        return false;
      }
    } else if (month == 2) {
      let leapYear = false;
      if ((!(year % 4) && year % 100) || !(year % 400)) leapYear = true;
      if (leapYear == false && day >= 29) {
        return false;
      } else if (leapYear == true && day > 29) {
        return false;
      }
    }
  } else {
    return false;
  }
  return true;
}

export const longDateFormat = (date) => {
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var d = new Date(date);
  var dayName = days[d.getDay()];
  var dayDate = d.getDate();
  var months = monthNames[d.getMonth()];
  var year = d.getFullYear();

  return dayName + " " + dayDate + " " + months + " " + year;
};

export const dateLanguage = (date, locale) => {
  let locales = locale === "en" ? "en-GB" : locale === "he" ? "hi" : locale;
  let dateLang = new Date(date);
  let options = {
    weekday: "short",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  let formattedDate = new Intl.DateTimeFormat(locales, options).format(
    dateLang
  );
  if (dateLang) {
    return formattedDate.replace(",", "");
  }
  return date;
};

export const shortDateLanguage = (date, locale) => {
  let locales = locale === "en" ? "en-GB" : locale === "he" ? "hi" : locale;
  let dateLang = new Date(date);
  let options = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  let formattedDate = new Intl.DateTimeFormat(locales, options).format(
    dateLang
  );
  if (dateLang) {
    return formattedDate.replace(",", "");
  }
  return date;
};


export const formatDateString = (dateString) => {
  const day = dateString.slice(0, 2);
  const month = dateString.slice(2, 4);
  const year = dateString.slice(4, 8);

  // Construct a date string in the format "MM/DD/YYYY"
  const formattedDateString = `${day}/${month}/${year}`;

  // Create a Date object
  // const formattedDate = new Date(formattedDateString);

  // Format the date using Intl.DateTimeFormat
  // const options = { day: "numeric", month: "short", year: "numeric" };
  // const dateFormatter = new Intl.DateTimeFormat("en-US", options);

  // return dateFormatter.format(formattedDate);
  return formattedDateString; // DD/MM/YYYY
};
