import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { isValidDate } from "../../lib/dateutils";
import { Form } from "react-bootstrap";

const AboutPatientForm = (props) => {
  const {
    onValueChange,
    bookingDetails,
    bDate,
    dateOptions,
    monthOptions,
    yearOptions,
    handleDateChange,
    validated,
    birthdate,
    isMailReq,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="main-manage-appointment-form">
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="form-group card-label">
            <Form.Label>
              {t("first_name")} <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="form-control"
              onChange={onValueChange}
              value={bookingDetails?.firstName}
              name="firstName"
              type="text"
              required
            />
            <Form.Control.Feedback type="invalid">
              {t("first_name_err_message")}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group card-label">
            <Form.Label>
              {t("last_name")} <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="form-control"
              onChange={onValueChange}
              value={bookingDetails?.lastName}
              name="lastName"
              type="text"
              required
            />
            <Form.Control.Feedback type="invalid">
              {t("last_name_err_message")}
            </Form.Control.Feedback>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group card-label">
            <label className="form-label">
              {t("birth_date")} <span className="text-danger">*</span>
            </label>
            <div className="d-flex align-items-center flex-wrap main-date-section">
              <div className="app-date-input-main">
                <Select
                  className="app-date-input-d"
                  cacheOptions
                  name="day"
                  value={
                    bDate.day != ""
                      ? { label: bDate.day, value: bDate.day }
                      : ""
                  }
                  defaultOptions={dateOptions}
                  isSearchable={true}
                  options={dateOptions}
                  onChange={handleDateChange}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={t("day_abbr")}
                />
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="none"
                  >
                    <path
                      fill="currentColor"
                      d="M12.473 5.474a.666.666 0 0 0-.946 0L8.474 8.527a.667.667 0 0 1-.947 0L4.474 5.474a.667.667 0 1 0-.947.94l3.06 3.06a2 2 0 0 0 2.827 0l3.06-3.06a.667.667 0 0 0 0-.94Z"
                    />
                  </svg>
                </span>
              </div>
              <div className="app-date-input-main">
                <Select
                  className="app-date-input"
                  cacheOptions
                  name="month"
                  value={
                    bDate.month != ""
                      ? { label: bDate.month, value: bDate.month }
                      : ""
                  }
                  defaultOptions={monthOptions}
                  isSearchable={true}
                  options={monthOptions}
                  onChange={handleDateChange}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={t("month_abbr")}
                />
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="none"
                  >
                    <path
                      fill="currentColor"
                      d="M12.473 5.474a.666.666 0 0 0-.946 0L8.474 8.527a.667.667 0 0 1-.947 0L4.474 5.474a.667.667 0 1 0-.947.94l3.06 3.06a2 2 0 0 0 2.827 0l3.06-3.06a.667.667 0 0 0 0-.94Z"
                    />
                  </svg>
                </span>
              </div>
              <div className="app-date-input-main">
                <Select
                  className="app-date-input-y"
                  cacheOptions
                  name="year"
                  value={
                    bDate.year != ""
                      ? { label: bDate.year, value: bDate.year }
                      : ""
                  }
                  defaultOptions={yearOptions}
                  isSearchable={true}
                  options={yearOptions}
                  onChange={handleDateChange}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={t("year_abbr")}
                />
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="none"
                  >
                    <path
                      fill="currentColor"
                      d="M12.473 5.474a.666.666 0 0 0-.946 0L8.474 8.527a.667.667 0 0 1-.947 0L4.474 5.474a.667.667 0 1 0-.947.94l3.06 3.06a2 2 0 0 0 2.827 0l3.06-3.06a.667.667 0 0 0 0-.94Z"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div style={{ fontSize: "0.875em", color: "#dc3545" }}>
              {validated &&
              (bookingDetails.dateOfBirth === "" || !isValidDate(birthdate))
                ? t("birth_date_err_message")
                : ""}
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-group card-label">
            <Form.Label>
              {t("phone_number")} <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="form-control"
              onChange={onValueChange}
              value={bookingDetails?.phoneNumber}
              name="phoneNumber"
              type="text"
              pattern="[0-9]*"
              minLength="10"
              maxLength="10"
              required
            />
            <Form.Control.Feedback type="invalid">
              {t("phone_number_err_message")}
            </Form.Control.Feedback>
          </div>
        </div>
        {!isMailReq && (
          <div className="col-md-12 col-sm-12">
            <div className="form-group card-label">
              <Form.Label>
                {t("email")} <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                className="form-control"
                onChange={onValueChange}
                value={bookingDetails.emailAddress}
                name="emailAddress"
                type="email"
                required={!isMailReq}
              />
              <Form.Control.Feedback type="invalid">
                {t("email_err_message")}
              </Form.Control.Feedback>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AboutPatientForm;
