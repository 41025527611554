import React, { useEffect, useMemo, useState } from "react";
import AboutPatientForm from "./aboutPatientForm";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import dayjs from "dayjs";
import {
  convertDate,
  convertDateTime,
  convertTime,
  convertTime12Hours,
  dateLanguage,
  formatDateString,
  formatLongDate,
  isValidDate,
  longDateFormat,
} from "../../lib/dateutils";
import { Link, useParams } from "react-router-dom";
import Spinner from "../booking/shared/spinner";
import Loader from "../common/Loader";

const AboutPatient = ({ startDate, endDate }) => {
  const _ = require("lodash");
  const [validated, setValidated] = useState(false);
  const [checkInvalidated, setCheckInValidated] = useState(false);
  const [patientDetails, setPatientDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    streetAddress: "",
    suburb: "",
    postcode: "",
  });
  const [bDate, setBDate] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [patientDOB, setPatientDOB] = useState("");
  const [step, setStep] = useState(1);
  const [modalStep, setModalStep] = useState(1);
  const [modalCancelStep, setModalCancelStep] = useState(1);
  const [modalCheckInStep, setModalCheckInStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [appConfirmLoading, setAppConfirmLoading] = useState(false);
  const [appCancelConfirmLoading, setAppCancelConfirmLoading] = useState(false);
  const [checkInLoading, setCheckInLoading] = useState(false);
  const [appointmentListData, setAppointmentListData] = useState([]);
  const [doctorsListData, setDoctorsListData] = useState([]);
  const [popularClinicObj, setPopularClinicObj] = useState({});
  const [confirmAppointmentData, setConfirmAppointmentData] = useState({});
  const [questionsData, setQuestionsData] = useState([]);
  const [checkInAns, setCheckInAns] = useState({});
  const { clinicCode, clinicId } = useParams();

  // modal states
  const handleConfirmClose = () => {
    setStep(2);
    setModalStep(1);
  };

  const [checkInShow, setCheckInShow] = useState(false);
  const handleCheckInClose = () => {
    setCheckInShow(false);
    // setModalCheckInStep(1);
    setStep(2);
  };

  let locale = localStorage.getItem("locale");

  const { t } = useTranslation();

  const onValueChange = (e) => {
    setPatientDetails({
      ...patientDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (e, name) => {
    setBDate((prev) => {
      return {
        ...prev,
        [name.name]: name.name === "month" ? e.label : e.value,
      };
    });
  };

  const handleCheckInChange = (e, item) => {
    const { value } = e.target;
    setCheckInAns({ ...checkInAns, [item.questionCode]: value });
  };

  let dateOptions = [];
  dateOptions = _.range(1, 32).map((i) => ({ value: `${i}`, label: `${i}` }));

  const monthOptions = useMemo(
    () => [
      { value: "01", label: t("january") },
      { value: "02", label: t("february") },
      { value: "03", label: t("march") },
      { value: "04", label: t("april") },
      { value: "05", label: t("may") },
      { value: "06", label: t("june") },
      { value: "07", label: t("july") },
      { value: "08", label: t("august") },
      { value: "09", label: t("september") },
      { value: "10", label: t("october") },
      { value: "11", label: t("november") },
      { value: "12", label: t("december") },
    ],
    [t]
  );

  let yearOptions = [];
  yearOptions = _.range(new Date().getFullYear(), 1930 - 1, -1).map((i) => ({
    value: `${i}`,
    label: `${i}`,
  }));

  useEffect(() => {
    // Update patientDOB when bDate is set
    if (bDate?.day !== "" && bDate?.month !== "" && bDate?.year !== "") {
      const mm = monthOptions?.filter((i) => i?.label === bDate?.month);
      if (bDate?.day?.length === 1) {
        setPatientDOB(`0${bDate?.day}/${mm[0]?.value}/${bDate?.year}`);
      } else {
        setPatientDOB(`${bDate?.day}/${mm[0]?.value}/${bDate?.year}`);
      }
    }
  }, [bDate, monthOptions]);

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);
    if (!form.checkValidity() || !isValidDate(patientDOB)) {
      return;
    }

    setStep(2);
    setLoading(true);

    fetch(
      `/server/appointment/patient/${clinicCode}/${patientDetails?.firstName}/${
        patientDetails?.lastName
      }/${patientDOB?.split("/").join("")}/${patientDetails?.phoneNumber}`
    )
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setAppointmentListData(data);
      })
      .catch((err) => {
        setLoading(false);
        console.error("ERROR: ", err);
      });
  };

  useEffect(() => {
    fetch(`/server/clinic/popular/${locale}`)
      .then((res) => res.json())
      .then((data) => {
        const clinic = data.filter((i) => i?.clinicId === Number(clinicId));
        setPopularClinicObj(clinic[0]);
      })
      .catch((error) => console.error("ERROR: ", error));

    fetch(`/server/practitioner/list/${clinicId}/${locale}`)
      .then((res) => res.json())
      .then((data) => {
        setDoctorsListData(data);
      })
      .catch((error) => console.error("ERROR: ", error));

    fetch(`/server/lookup/checkin/question/${clinicId}/${locale}`)
      .then((res) => res.json())
      .then((data) => {
        setQuestionsData(data);
      })
      .catch((error) => {
        console.error("ERROR: ", error);
      });
  }, [locale, clinicId]);

  const getDoctorByCode = (code) => {
    let doctor = doctorsListData.filter((i) => Number(i.doctorCode) === code);
    return doctor[0];
  };

  const confirmAppointment = (item) => {
    setModalStep(1);
    setConfirmAppointmentData(item);
    setCheckInShow(false);
    setStep(4);
  };

  const cancelAppointment = (item) => {
    setModalCancelStep(1);
    setConfirmAppointmentData(item);
    setCheckInShow(false);
    setStep(5);
  };

  const handleConfirm = () => {
    setAppConfirmLoading(true);
    let payload = {
      clinicCode: clinicCode,
      appointmentId: confirmAppointmentData?.appointmentId,
    };

    fetch("/server/appointment/confirm", {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => {
        setAppConfirmLoading(false);
        setModalStep(2);
      })
      .catch((err) => {
        setAppConfirmLoading(false);
        console.error("ERROR: ", err);
      });
  };

  const handleCancelConfirm = () => {
    setAppCancelConfirmLoading(true);
    let payload = {
      clinicCode: clinicCode,
      appointmentId: confirmAppointmentData?.appointmentId,
    };

    fetch("/server/appointment/appointmentId", {
      method: "DELETE",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => {
        setAppCancelConfirmLoading(false);
        setModalCancelStep(2);
        // setStep(2);
        const filteredAppointments = appointmentListData.filter(
          (appointment) =>
            appointment.appointmentId !== confirmAppointmentData?.appointmentId
        );
        setAppointmentListData(filteredAppointments);
      })
      .catch((err) => {
        setAppCancelConfirmLoading(false);
        console.error("ERROR: ", err);
      });
  };

  const checkInQuestions = (item) => {
    setStep(3);
    setModalCheckInStep(0);
    setConfirmAppointmentData(item);
  };

  const handleCheckInSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setCheckInValidated(true);
      return;
    }
    setCheckInLoading(true);

    // if all answer is no then called checkIn api
    if (!Object.values(checkInAns).includes("yes")) {
      // show thank you message
      let payload = {
        clinicCode: clinicCode,
        appointmentId: confirmAppointmentData?.appointmentId,
      };
      fetch("/server/appointment/checkin", {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: {
          "Content-type": "application/json",
        },
      })
        .then((res) => {
          setCheckInLoading(false);
          setCheckInShow(true);
          setModalCheckInStep(1);
        })
        .catch((err) => {
          setCheckInLoading(false);
          console.error("ERROR: ", err);
        });
    } else {
      setCheckInLoading(false);
      setCheckInShow(true);
      setModalCheckInStep(2);
    }
  };

  const givenDateFormat = (date) => {
    const formatDate = new Date(
      Number(date.slice(4, 8)), // Year
      Number(date.slice(2, 4)) - 1, // Month (0-based)
      Number(date.slice(0, 2)), // Day
      Number(date.slice(8, 10)), // Hours
      Number(date.slice(10, 12)) // Minutes
    );
    return formatDate;
  };

  const checkInDisabled = (givenDateStr) => {
    const givenDate = givenDateFormat(givenDateStr);
    const currentDate = new Date();
    const oneHourAgo = new Date(givenDate);
    const tenMinutesAfter = new Date(givenDate);
    oneHourAgo.setHours(givenDate.getHours() - 1);
    tenMinutesAfter.setMinutes(givenDate.getMinutes() + 10);

    return !(currentDate >= oneHourAgo && currentDate <= tenMinutesAfter);
  };

  const confirmDisable = (date) => {
    return dayjs().isAfter(dayjs(convertDateTime(date)));
  };

  const cancelDisable = (givenDateStr) => {
    const givenDate = givenDateFormat(givenDateStr);
    const currentDate = new Date();
    const twoHourAgo = new Date(givenDate);
    twoHourAgo.setHours(givenDate.getHours() - 2);

    return currentDate >= twoHourAgo;
  };

  const refetchAppointmentList = async (flag) => {
    if (flag === "confirm") {
      setAppConfirmLoading(true);
    }
    if (flag === "cancel") {
      setAppCancelConfirmLoading(true);
    }

    if (flag === "checkIn") {
      setCheckInLoading(true);
    }

    await fetch(
      `/server/appointment/patient/${clinicCode}/${patientDetails?.firstName}/${
        patientDetails?.lastName
      }/${patientDOB?.split("/").join("")}/${patientDetails?.phoneNumber}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (flag === "checkIn") {
          setCheckInLoading(false);
          setCheckInShow(false);
        }

        if (flag === "cancel") {
          setAppCancelConfirmLoading(false);
        }

        if (flag === "confirm") {
          setAppConfirmLoading(false);
        }
        setStep(2);
        setAppointmentListData(data);
      })
      .catch((err) => {
        if (flag === "confirm") {
          setAppConfirmLoading(false);
        }

        if (flag === "cancel") {
          setAppCancelConfirmLoading(false);
        }

        if (flag === "checkIn") {
          setCheckInLoading(false);
        }
        console.error("ERROR: ", err);
      });
  };

  const handleConfirmCloseStep = async () => {
    setAppConfirmLoading(true);
    refetchAppointmentList("confirm");
  };

  const handleCancelClose = async () => {
    setAppCancelConfirmLoading(true);
    refetchAppointmentList("cancel");
  };

  const refetchCheckIn = async () => {
    setCheckInLoading(true);
    refetchAppointmentList("checkIn");
  };

  return (
    <div className="container footer-stick">
      {/* manage appointment book step */}
      {step === 1 && (
        <div className="main-manage-appointment-section">
          {/* <div className="d-flex align-items-center page-title-text">
            <Link to={`/${locale}/`} className="me-3">
              <img
                src="/assets/images/doctors/right-artrow-c.png"
                alt="back_arrow"
                className="title-back-arrow"
              />
            </Link>
            <h3>{t("manage_appointment")}</h3>
          </div> */}
          <div className="appoi-nav-main back-button-container pt-0 px-0">
            <div className="appoi-nav-inner-main">
              <Link to={`/${locale}/`} className="btn back-button">
                <i className="fas fa-arrow-left filled"></i>
                <span>{t("back")}</span>
              </Link>
            </div>
          </div>
          <div className="mb-4">
            <h3>{t("manage_appointment")}</h3>
          </div>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <AboutPatientForm
              onValueChange={onValueChange}
              bookingDetails={patientDetails}
              bDate={bDate}
              dateOptions={dateOptions}
              monthOptions={monthOptions}
              yearOptions={yearOptions}
              handleDateChange={handleDateChange}
              validated={validated}
              birthdate={patientDOB}
              isMailReq={true}
            />
            <div className="d-flex align-items-center justify-content-center flex-wrap main-manage-appointment-button">
              {/* <Link to={"/"} className="btn ct-button-cancel">
                {t("no")}
              </Link> */}
              <button type="submit" className="btn ct-button-next">
                {t("next")}
              </button>
            </div>
          </Form>
        </div>
      )}

      {/* appointments list step */}
      {loading ? (
        <div className="main-loader">
          <Spinner />
        </div>
      ) : (
        step === 2 && (
          <div className="appointments-main-section">
            {/* <div className="d-flex align-items-center mb-5">
              <div onClick={() => setStep(1)} className="me-3">
                <img
                  src="/assets/images/doctors/right-artrow-c.png"
                  alt="back_arrow"
                  className="title-back-arrow"
                />
              </div>
              <h3>{t("appointments")}</h3>
            </div> */}
            <div className="appoi-nav-main back-button-container pt-0 px-0">
              <div className="appoi-nav-inner-main">
                <button onClick={() => setStep(1)} className="btn back-button">
                  <i className="fas fa-arrow-left filled"></i>
                  <span>{t("back")}</span>
                </button>
              </div>
            </div>
            <div className="mb-4">
              <h3>{t("appointments")}</h3>
            </div>
            {appointmentListData.length !== 0 ? (
              <div className="row">
                {appointmentListData?.map((i, index) => (
                  <div key={index} className="col-md-12">
                    <div className="card-appointment-box">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="d-flex align-items-center mb-3">
                            <div className="lt-doctor-img">
                              <img
                                src={`/assets/images/doctors/${
                                  getDoctorByCode(i?.doctorCode)?.doctorImg
                                }`}
                                className="img-fluid profile-img"
                                alt="User"
                              />
                            </div>
                            <p className="mb-0 title-text">
                              {getDoctorByCode(i?.doctorCode)?.doctorName}
                            </p>
                          </div>
                          <div className="card-appointmen-list">
                            <ul>
                              <li className="d-flex align-items-center">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={18}
                                    height={18}
                                    fill="none"
                                  >
                                    <g fill="currentColor" clipPath="url(#a)">
                                      <path d="M9 0a9 9 0 1 0 9 9 9.01 9.01 0 0 0-9-9Zm0 16.5A7.5 7.5 0 1 1 16.5 9 7.509 7.509 0 0 1 9 16.5Z" />
                                      <path d="M9 4.5a.75.75 0 0 0-.75.75v3.244l-2.528 1.584a.752.752 0 0 0 .796 1.275l2.88-1.8a.75.75 0 0 0 .352-.644V5.25A.75.75 0 0 0 9 4.5Z" />
                                    </g>
                                    <defs>
                                      <clipPath id="a">
                                        <path fill="#fff" d="M0 0h18v18H0z" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                                <p className="m-0">
                                  {convertTime12Hours(i?.appointmentDate)}
                                </p>
                              </li>
                              <li className="d-flex align-items-center">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={18}
                                    height={18}
                                    fill="none"
                                  >
                                    <g fill="currentColor" clipPath="url(#a)">
                                      <path d="M14.25 1.5h-.75V.75a.75.75 0 1 0-1.5 0v.75H6V.75a.75.75 0 0 0-1.5 0v.75h-.75A3.755 3.755 0 0 0 0 5.25v9A3.754 3.754 0 0 0 3.75 18h10.5A3.754 3.754 0 0 0 18 14.25v-9a3.755 3.755 0 0 0-3.75-3.75ZM1.5 5.25A2.25 2.25 0 0 1 3.75 3h10.5a2.25 2.25 0 0 1 2.25 2.25V6h-15v-.75ZM14.25 16.5H3.75a2.25 2.25 0 0 1-2.25-2.25V7.5h15v6.75a2.25 2.25 0 0 1-2.25 2.25Z" />
                                      <path d="M9 12.375a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25ZM5.25 12.375a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25ZM12.75 12.375a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z" />
                                    </g>
                                    <defs>
                                      <clipPath id="a">
                                        <path fill="#fff" d="M0 0h18v18H0z" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                                <p className="m-0">
                                  {dateLanguage(
                                    longDateFormat(
                                      convertDate(i?.appointmentDate).toString()
                                    ),
                                    locale
                                  )}
                                  {/* {formatDateString(i?.appointmentDate)} */}
                                </p>
                              </li>
                            </ul>
                            {/* <p className="mb-0">
                              {t("with")}{" "}
                              <strong>
                                {getDoctorByCode(i?.doctorCode)?.doctorName}
                              </strong>{" "}
                              {t("for")}{" "}
                              <strong>{popularClinicObj?.clinicName}</strong>
                            </p> */}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card-appointment-margin">
                            {i?.appointmentStatus && (
                              <p className="fw-bold mb-1">
                                {t("status")}: {i?.appointmentStatus}
                              </p>
                            )}

                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/doctors/plus-icon.png"
                                alt=""
                                className="me-2"
                              />
                              <p className="fw-bold mb-0">
                                {popularClinicObj?.clinicName}
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <img
                                src="/assets/images/doctors/location-icon.png"
                                alt=""
                                className="me-2"
                              />
                              <p className="fw-bold mb-0">
                                {popularClinicObj?.address}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex align-items-center flex-wrap card-appointment-button">
                            <button
                              type="button"
                              className="btn btn-outline-primary"
                              onClick={() => checkInQuestions(i)}
                              disabled={
                                checkInDisabled(i?.appointmentDate) ||
                                "ARRIVED" === i?.appointmentStatus
                              }
                            >
                              {t("check_in")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-primary"
                              onClick={() => confirmAppointment(i)}
                              disabled={
                                confirmDisable(i?.appointmentDate) ||
                                "ARRIVED" === i?.appointmentStatus
                              }
                            >
                              {t("confirm_appointment")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-primary"
                              onClick={() => cancelAppointment(i)}
                              disabled={
                                cancelDisable(i?.appointmentDate) ||
                                "ARRIVED" === i?.appointmentStatus
                              }
                            >
                              {t("cancel_appointment")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center mb-5 fw-bold no-data-msg">
                {t("contact_clinic_msg")}
              </div>
            )}
          </div>
        )
      )}

      {/* check in step */}
      {step === 3 && (
        <>
          {modalCheckInStep === 0 && (
            <>
              <div className="appoi-nav-main back-button-container p-0 pt-3">
                <div className="appoi-nav-inner-main">
                  <button
                    onClick={() => setStep(2)}
                    className="btn back-button"
                  >
                    <i className="fas fa-arrow-left filled"></i>
                    <span>{t("back")}</span>
                  </button>
                </div>
              </div>
              <div className="main-check-in-section">
                <div className="main-check-in-inner">
                  <h3>{t("check_in")}</h3>
                  <Form
                    noValidate
                    validated={checkInvalidated}
                    onSubmit={handleCheckInSubmit}
                  >
                    {questionsData &&
                      questionsData?.map((i, index) => (
                        <Form.Group className="main-check-form" key={index}>
                          <div className="main-check-form-inner">
                            <Form.Label>{i?.shortDescription}</Form.Label>
                            <div className="main-check-form-check">
                              <Form.Check
                                type="radio"
                                label={t("label_yes")}
                                name={i?.questionCode}
                                value="yes"
                                onChange={(e) => handleCheckInChange(e, i)}
                                id={i?.questionCode + "1"}
                                required
                              />
                              <Form.Check
                                type="radio"
                                label={t("label_no")}
                                name={i?.questionCode}
                                value="no"
                                onChange={(e) => handleCheckInChange(e, i)}
                                id={i?.questionCode + "2"}
                                required
                              />
                            </div>
                          </div>
                          <Form.Control.Feedback type="invalid">
                            {t("required_msg")}
                          </Form.Control.Feedback>
                        </Form.Group>
                      ))}

                    <Button type="submit" variant="outline-primary">
                      {checkInLoading ? (
                        <Loader color="primary" size="sm" />
                      ) : (
                        t("submit")
                      )}
                    </Button>
                  </Form>
                </div>
              </div>
            </>
          )}

          {checkInShow && (
            <>
              {/* {modalCheckInStep === 2 && (
                <div className="appoi-nav-main back-button-container p-0 pt-3">
                  <div className="appoi-nav-inner-main">
                    <button
                      onClick={() => {
                        setModalCheckInStep(0);
                        setCheckInShow(false);
                      }}
                      className="btn back-button"
                    >
                      <i className="fas fa-arrow-left filled"></i>
                      <span>{t("back")}</span>
                    </button>
                  </div>
                </div>
              )} */}
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-lg-6 success-page-cont mt-4">
                    <div className="card success-card">
                      <div className="card-body">
                        <div className="success-cont">
                          {modalCheckInStep === 1 && (
                            <>
                              <h4 className="h4 fw-semibold mb-4 text-center">
                                {t("thank_You_check_in")}
                              </h4>
                              <div className="d-flex align-items-center justify-content-center card-appointment-button">
                                <Button
                                  variant="outline-primary"
                                  className="ct-button-close close-btn"
                                  onClick={refetchCheckIn}
                                >
                                  {checkInLoading ? (
                                    <Loader color="light" size="sm" />
                                  ) : (
                                    t("close")
                                  )}
                                </Button>
                              </div>
                            </>
                          )}
                          {modalCheckInStep === 2 && (
                            <>
                              <h4 className="h4 fw-semibold mb-4 text-center">
                                {t("assist_msg")}
                              </h4>
                              <div className="d-flex align-items-center justify-content-center card-appointment-button">
                                <Button
                                  variant="outline-primary"
                                  className="ct-button-close close-btn"
                                  onClick={handleCheckInClose}
                                >
                                  {t("close")}
                                </Button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {/* confirm appointment step */}
      {step === 4 && (
        <div className="container-fluid">
          {/* {modalStep === 0 && (
                      <>
                        <h3 className="mb-3">{t("contact_clinic_msg")}</h3>
                      </>
                    )} */}

          {modalStep === 1 && (
            <>
              <div className="appoi-nav-main back-button-container px-0">
                <div className="appoi-nav-inner-main">
                  <button
                    onClick={() => setStep(2)}
                    className="btn back-button"
                  >
                    <i className="fas fa-arrow-left filled"></i>
                    <span>{t("back")}</span>
                  </button>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-6 success-page-cont mt-4">
                  <div className="card success-card">
                    <div className="card-body">
                      <div className="success-cont">
                        <h1>{t("confirm_msg")}</h1>
                        <div className="doctor-detail">
                          <div className="doctor-detail-left">
                            <img
                              src={`/assets/images/doctors/${
                                getDoctorByCode(
                                  confirmAppointmentData?.doctorCode
                                )?.doctorImg
                              }`}
                              className="img-fluid"
                              alt="User"
                            />
                          </div>
                          <div className="doctor-detail-right">
                            <h3>
                              {
                                getDoctorByCode(
                                  confirmAppointmentData?.doctorCode
                                )?.doctorName
                              }
                            </h3>
                            <p>
                              <span>
                                <img
                                  src="/assets/images/doctors/clock-icon.png"
                                  alt=""
                                />
                              </span>
                              <span>
                                {dateLanguage(
                                  longDateFormat(
                                    convertDate(
                                      confirmAppointmentData?.appointmentDate
                                    ).toString()
                                  ),
                                  locale
                                )}
                                {/* {formatDateString(
                                  confirmAppointmentData?.appointmentDate
                                )} */}
                                {" " + t("at") + " "}
                                {convertTime(
                                  confirmAppointmentData?.appointmentDate
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="main-details-block">
                          {/* <img
                            src={`/assets/images/clinics/${popularClinicObj?.logoFile}`}
                            alt=""
                            className="main-details-block-image"
                          /> */}
                          <div className="main-details-block-container">
                            <div className="details">
                              <img
                                src={`/assets/images/doctors/plus-icon.png`}
                                alt=""
                              />
                              <h4>{popularClinicObj?.clinicName}</h4>
                            </div>
                            <div className="details">
                              <img
                                src="/assets/images/doctors/location-icon.png"
                                alt=""
                              />
                              <h4>{popularClinicObj?.address}</h4>
                            </div>
                          </div>
                        </div>
                        <div className="card-appointment-button">
                          {/* <Button
                            variant="outline-primary rt-btn"
                            className="me-3"
                            onClick={() => setStep(2)}
                          >
                            {t("not_sure")}
                          </Button> */}
                          <Button
                            variant="outline-primary"
                            className="cn-loader-btn"
                            onClick={handleConfirm}
                          >
                            {appConfirmLoading ? (
                              <Loader color="light" size="sm" />
                            ) : (
                              t("confirm_details")
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {modalStep === 2 && (
            <>
              <div className="row justify-content-center">
                <div className="col-lg-6 success-page-cont mt-4">
                  <div className="card success-card">
                    <div className="card-body">
                      <div className="success-cont">
                        <h3>{t("thank_you_msg")}</h3>
                        <div className="confirm-app-modal-box">
                          <div className="confirm-app-modal-dr">
                            <h1 className="text-center mb-3">
                              {popularClinicObj?.clinicName}
                            </h1>
                          </div>
                          <div className="confirm-app-modal-list text-start">
                            <ul className="m-0 p-0 confirm-app-modal-list-ul">
                              <li className="d-flex align-items-center mb-2">
                                <span className="d-flex align-items-center">
                                  <svg
                                    className="me-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={18}
                                    height={18}
                                    fill="none"
                                  >
                                    <g fill="currentColor" clipPath="url(#a)">
                                      <path d="M9 0a9 9 0 1 0 9 9 9.01 9.01 0 0 0-9-9Zm0 16.5A7.5 7.5 0 1 1 16.5 9 7.509 7.509 0 0 1 9 16.5Z" />
                                      <path d="M9 4.5a.75.75 0 0 0-.75.75v3.244l-2.528 1.584a.752.752 0 0 0 .796 1.275l2.88-1.8a.75.75 0 0 0 .352-.644V5.25A.75.75 0 0 0 9 4.5Z" />
                                    </g>
                                    <defs>
                                      <clipPath id="a">
                                        <path fill="#fff" d="M0 0h18v18H0z" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                                <p className="m-0">
                                  {convertTime12Hours(
                                    confirmAppointmentData?.appointmentDate
                                  )}
                                </p>
                              </li>
                              <li className="d-flex align-items-center mb-2">
                                <span className="d-flex align-items-center">
                                  <svg
                                    className="me-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={18}
                                    height={18}
                                    fill="none"
                                  >
                                    <g fill="currentColor" clipPath="url(#a)">
                                      <path d="M14.25 1.5h-.75V.75a.75.75 0 1 0-1.5 0v.75H6V.75a.75.75 0 0 0-1.5 0v.75h-.75A3.755 3.755 0 0 0 0 5.25v9A3.754 3.754 0 0 0 3.75 18h10.5A3.754 3.754 0 0 0 18 14.25v-9a3.755 3.755 0 0 0-3.75-3.75ZM1.5 5.25A2.25 2.25 0 0 1 3.75 3h10.5a2.25 2.25 0 0 1 2.25 2.25V6h-15v-.75ZM14.25 16.5H3.75a2.25 2.25 0 0 1-2.25-2.25V7.5h15v6.75a2.25 2.25 0 0 1-2.25 2.25Z" />
                                      <path d="M9 12.375a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25ZM5.25 12.375a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25ZM12.75 12.375a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z" />
                                    </g>
                                    <defs>
                                      <clipPath id="a">
                                        <path fill="#fff" d="M0 0h18v18H0z" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                                <p className="m-0">
                                  {dateLanguage(
                                    longDateFormat(
                                      formatLongDate(
                                        convertDate(
                                          confirmAppointmentData?.appointmentDate
                                        )
                                      )
                                    ),
                                    locale
                                  )}
                                  {/* {formatDateString(confirmAppointmentData?.appointmentDate)} */}
                                </p>
                              </li>
                              <li className="d-flex align-items-center mb-2">
                                <span className="d-flex align-items-center">
                                  <svg
                                    className="me-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={18}
                                    height={18}
                                    fill="none"
                                  >
                                    <g fill="currentColor" clipPath="url(#a)">
                                      <path d="M9 4.5a3 3 0 1 0 0 6 3 3 0 0 0 0-6ZM9 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z" />
                                      <path d="M9 18a3.953 3.953 0 0 1-3.233-1.65c-2.859-3.943-4.308-6.906-4.308-8.81a7.541 7.541 0 0 1 15.082 0c0 1.904-1.45 4.868-4.308 8.81A3.953 3.953 0 0 1 9 18ZM9 1.636a5.912 5.912 0 0 0-5.906 5.906c0 1.507 1.42 4.295 3.997 7.849a2.358 2.358 0 0 0 3.818 0c2.577-3.554 3.996-6.342 3.996-7.85A5.912 5.912 0 0 0 9 1.637Z" />
                                    </g>
                                    <defs>
                                      <clipPath id="a">
                                        <path fill="#fff" d="M0 0h18v18H0z" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </span>
                                <p className="m-0">
                                  {popularClinicObj?.address}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="card-appointment-button">
                          <Button
                            variant="outline-primary"
                            className="close-btn"
                            onClick={() => handleConfirmCloseStep()}
                          >
                            {appConfirmLoading ? (
                              <Loader color="light" size="sm" />
                            ) : (
                              t("close")
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* {modalStep === 3 && (
                      <>
                        <h3 className="h4 fw-semibold mb-3 text-center">
                          {t("review_msg")}
                        </h3>
                      </>
                    )}

                    {(modalStep === 3) && (
                      <div className="card-appointment-button">
                        <Button
                          variant="outline-primary"
                          className="ct-button-close close-btn "
                          onClick={handleConfirmClose}
                        >
                          {t("close")}
                        </Button>
                      </div>
                    )} */}
        </div>
      )}

      {/* cancel appointment step */}
      {step === 5 && (
        <div className="container-fluid">
          {modalCancelStep === 1 && (
            <>
              <div className="appoi-nav-main back-button-container px-0">
                <div className="appoi-nav-inner-main">
                  <button
                    onClick={() => setStep(2)}
                    className="btn back-button"
                  >
                    <i className="fas fa-arrow-left filled"></i>
                    <span>{t("back")}</span>
                  </button>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-lg-6 success-page-cont">
                  <div className="card success-card">
                    <div className="card-body">
                      <div className="success-cont">
                        <div className="confirm-app-inner text-center">
                          <h1>{t("confirm_ans")}</h1>
                          <div className="doctor-detail">
                            <div className="doctor-detail-left">
                              <img
                                src={`/assets/images/doctors/${
                                  getDoctorByCode(
                                    confirmAppointmentData?.doctorCode
                                  )?.doctorImg
                                }`}
                                className="img-fluid"
                                alt="User"
                              />
                            </div>
                            <div className="doctor-detail-right">
                              <h3>
                                {
                                  getDoctorByCode(
                                    confirmAppointmentData?.doctorCode
                                  )?.doctorName
                                }
                              </h3>
                              <p>
                                <span>
                                  <img
                                    src="/assets/images/doctors/clock-icon.png"
                                    alt=""
                                  />
                                </span>
                                <span>
                                  {dateLanguage(
                                    longDateFormat(
                                      convertDate(
                                        confirmAppointmentData?.appointmentDate
                                      ).toString()
                                    ),
                                    locale
                                  )}
                                  {" " + t("at") + " "}
                                  {convertTime(
                                    confirmAppointmentData?.appointmentDate
                                  )}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="main-details-block">
                            <div className="main-details-block-container">
                              <div className="details">
                                <img
                                  src={`/assets/images/doctors/plus-icon.png`}
                                  alt=""
                                />
                                <h4>{popularClinicObj?.clinicName}</h4>
                              </div>
                              <div className="details">
                                <img
                                  src="/assets/images/doctors/location-icon.png"
                                  alt=""
                                />
                                <h4>{popularClinicObj?.address}</h4>
                              </div>
                            </div>
                          </div>

                          <div className="card-appointment-button">
                            <Button
                              variant="outline-primary"
                              className="ct-button-cancel ct-cancel-spacing"
                              onClick={handleCancelConfirm}
                            >
                              {appCancelConfirmLoading ? (
                                <Loader color="light" size="sm" />
                              ) : (
                                t("confirm_cancellation")
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {modalCancelStep === 2 && (
            <div className="row justify-content-center">
              <div className="col-lg-6 success-page-cont mt-4">
                <div className="card success-card">
                  <div className="card-body">
                    <div className="success-cont">
                      <div className="confirm-app-inner text-center">
                        <h3 className="mb-3">{t("cancel_confirm_msg")}</h3>
                        <div className="card-appointment-button">
                          <Button
                            variant="outline-primary"
                            className="ct-button-cancel ct-cancel-spacing"
                            onClick={handleCancelClose}
                          >
                            {appCancelConfirmLoading ? (
                              <Loader color="light" size="sm" />
                            ) : (
                              t("close")
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AboutPatient;
