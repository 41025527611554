let he = {
  translations: {
    home: "घर",
    checkin: "चेक इन",
    manage_booking: "बुकिंग की व्यवस्था करना",
    back: "पीछे",
    change: "परिवर्तन",
    about_practice: "इस प्रथा के बारे में",
    billing_policy: "बिलिंग नीति",
    billing_policy_description: `सभी रोगियों को उनके लिए अग्रिम भुगतान करना आवश्यक है
    $35.00 की जेब से खर्च के साथ परामर्श
    मानक नियुक्तियां। इसके बाद हम क्लेम सबमिट करेंगे
    सीधे मेडिकेयर के लिए। 16 वर्ष से कम आयु के बच्चे एक वैध के साथ
    मेडिकेयर कार्ड अंतराल और रियायत कार्ड का भुगतान नहीं करेगा
    धारक रियायती दर का भुगतान करेंगे।`,
    cancellation_policy: "रद्दीकरण / गैर-उपस्थिति नीति",
    cancellation_policy_description: `यदि आप अब नियुक्ति में शामिल नहीं हो सकते हैं, तो कृपया
    इस वेबसाइट या कॉलिंग के माध्यम से रद्द या पुनर्निर्धारित करें
    हमारे रिसेप्शनिस्ट। कृपया ध्यान दें कि $39.75 का शुल्क होगा
    यदि आप अपने से 2 घंटे से कम समय पहले रद्द करते हैं तो आवेदन करें
    नियुक्ति या आपकी नियुक्ति में शामिल होने में विफल।`,
    question1: `यह नियुक्ति किसके लिए है?`,
    question1opt1: `खुद`,
    question1opt2: `किसी और को`,
    question2: `क्या आपने पहले फॉरवर्ड मेडिकल जीपी क्लिनिक में भाग लिया है?`,
    question2opt1: `नहीं, मैं इस अभ्यास के लिए नया हूँ`,
    question2opt2: `हां, मैं एक मौजूदा मरीज हूं`,
    question3: `आपको किस प्रकार की नियुक्ति की आवश्यकता है?`,
    about_you: "आपके बारे में",
    first_name: "पहला नाम",
    first_name_err_message: "प्रथम नाम खाली नहीं होना चाहिए।",
    last_name: "उपनाम",
    last_name_err_message: "अंतिम नाम खाली नहीं होना चाहिए।",
    birth_date: "तारीख(दिन/माह/वर्ष)",
    birth_date_err_message: "कृपया मान्य तिथि जोड़ें।",
    phone_number: "फ़ोन नंबर",
    phone_number_err_message: "कृपया एक वैध नंबर डालें",
    email: "ईमेल",
    email_err_message: "कृपया वैध ईमेल दर्ज़ करें",
    address: "पता",
    street_address: "गली का पता",
    street_address_err_message: "पता खाली नहीं होना चाहिए",
    suburb: "उपनगर",
    suburb_err_message: "उपनगर खाली नहीं होना चाहिए",
    postcode: "पोस्टकोड",
    postcode_err_message: "कृपया वैध पोस्टकोड दर्ज करें",
    read_and_accept_term_and_condition: `मैंने नियम और शर्तें पढ़ ली हैं और मैं उन्हें स्वीकार करता हूं`,
    read_and_accept_term_and_condition_feedback: `सबमिट करने से पहले आपको सहमत होना होगा।`,
    accept_term_and_condition: `कृपया नियम और शर्तें स्वीकार करें`,
    continue: "जारी रखना",
    more_appointment: "अधिक नियुक्तियां",
    appointment_confirmed: `आपकी नियुक्ति पक्की हो गई है!`,
    forward_medical_gp_clinic: `फॉरवर्ड मेडिकल जीपी क्लिनिक`,
    close: "बंद करना",
    help_and_support: "सहायता और समर्थन",
    privacy_and_policy: "गोपनीयता नीति",
    term_of_use: "उपयोग की शर्त",
    all_right_reserved: `2020 डॉक्युरे। सर्वाधिकार सुरक्षित।`,
    no_appointment_available: "कोई अपॉइंटमेंट उपलब्ध नहीं है",
    morning: "सुबह",
    afternoon: "दोपहर",
    speak: "बोलता हे",
    appt_type_message:
      "अब उपलब्ध नहीं है, क्या आप चाहते हैं अन्य अपॉइंटमेंट दिनांक/समय चुनें?",
    appt_not_avail:
      "अब उपलब्ध नहीं है, क्या आप कोई भिन्न अपॉइंटमेंट दिनांक/समय चुनना चाहते हैं?",
    appt_time: "क्षमा करें चयनित नियुक्ति",
    book_your_next: "अपना अगला बुक करें",
    healthcare_appointment: "स्वास्थ्य देखभाल नियुक्ति।",
    find_pactitioners:
      "अपने पसंदीदा चिकित्सकों को खोजें, बुक करें और अपनी देखभाल टीम में जोड़ें।",
    book_appointment_now: "अभी अपॉइंटमेंट बुक करें",
    search: "खोज",
    practices: "आचरण",
    practitioners: "चिकित्सकों",
    popular_practices: "लोकप्रिय प्रथाएं",
    at: "पर",
    yes: "हाँ",
    no: "नहीं",
    general_error_title: "कुछ गलत हो गया!",
    general_error_prefix:
      "क्षमा करें, हमें आपके क्लिनिक की बुकिंग प्रणाली से जुड़ने में कठिनाइयाँ हैं, कृपया अपने क्लिनिक से यहाँ संपर्क करें",
    general_error_suffix: "फोन पर अपॉइंटमेंट बुक करने के लिए।",
    no_appointments_available: "कोई अपॉइंटमेंट उपलब्ध नहीं है",
    we_speak_your_language: "हम आपकी भाषा बोलते हैं",
    confirm_details_QA: "अपने अपॉइंटमेंट विवरण की पुष्टि करें",
    appointment_type: "नियुक्ति प्रकार",
    time: "समय",
    date: "तारीख",
    doctor: "चिकित्सक",
    clinic: "क्लिनिक",
    go_back_make_change: "वापस जाएं और परिवर्तन करें",
    confirm_details: "विवरण की पुष्टि करें",
    set: "सम्पादित करना",
    january: "जनवरी",
    february: "फ़रवरी",
    march: "मार्च",
    april: "अप्रैल",
    may: "मई",
    june: "जून",
    july: "जुलाई",
    august: "अगस्त",
    september: "सितंबर",
    october: "अक्टूबर",
    november: "नवंबर",
    december: "दिसंबर",
    day_abbr: "Day",
    month_abbr: "Month",
    year_abbr: "Year",
    select_option: "select option",
    state: "state",
    p_introduction: "यह गोपनीयता नीति निर्धारित करती है कि हम आपकी व्यक्तिगत जानकारी को कैसे और क्यों एकत्र, संग्रहीत, उपयोग और प्रकट करते हैं, और यदि आपके पास इस बारे में कोई प्रश्न है कि हम आपकी व्यक्तिगत जानकारी को कैसे संभालते हैं तो हमसे कैसे संपर्क करें।",
    p_information: ""
  },
};

export default he;
