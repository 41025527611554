import React from "react";

const Loader = (props) => (
  <div className="">
    <div
      className={`spinner-border spinner-border-${props.size} text-${
        props.color ? props.color : "success"
      }`}
      role="status"
    >
      {/* <span className="sr-only">{props.message}</span> */}
    </div>
  </div>
);

Loader.defaultProps = {
  message: "Loading...",
};

export default Loader;
