import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

const localeList = [
  { name: "English", code: "en", lang: "English" },
  { name: "中文", code: "zh", lang: "Chinese" },
];

const defaultLocale = localStorage["locale"] ? localStorage["locale"] : "en";

const Header = (props) => {
  let locale = localStorage.getItem("locale");
  const router = useLocation();
  const source = new URLSearchParams(router.search).get("source");

  let mydata = useLocation();
  let searchParams =
    typeof mydata.search != "undefined" ? `${mydata?.search}` : "";
  const navigate = useNavigate();
  const [currentLocale, setCurrentLocale] = useState(defaultLocale);

  const { t, i18n } = useTranslation();
  let pathnames = "https://www.nextappointment.com.au/patient/booking";

  let routeLang = router.pathname.substring(1, 3);
  useEffect(() => {
    i18n.changeLanguage(routeLang);
    setCurrentLocale(routeLang);
    localStorage.setItem("locale", routeLang);
  }, []);

  const [width, setWidth] = useState();
  const breakpoint = 991;

  useEffect(() => {
    if (width === undefined) {
      setWidth(window.innerWidth);
    }
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
  }, [width]);

  const onHandleMobileMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.add("menu-opened");
  };

  const onhandleCloseMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("menu-opened");
  };

  const handleLangaugaeClick = (locales) => {
    i18n.changeLanguage(locales);
    setCurrentLocale(locales);
    localStorage.setItem("locale", locales);
    let path = router.pathname.substring(3);
    if (path === "") {
      navigate(`/${locales}${searchParams}`);
    } else if (path === "/booking/success" && locale === locales) {
      navigate(`/${locales}/booking/success${searchParams}`, {
        state: { ...mydata.state },
      });
    } else if (path === "/booking/success") {
      fetch(
        `/server/practitioner/list/${mydata?.state?.clinic?.clinicData?.clinicId}/${locales}`
      )
        .then((res) => res.json())
        .then((data) => {
          fetch(
            `/server/clinic/contact/${mydata?.state?.clinic?.clinicData?.clinicId}/${locales}`
          )
            .then((resp) => resp.json())
            .then((clinicData) => {
              let selectedDoctor = data.find(
                (item) =>
                  item.doctorCode ===
                  mydata?.state?.doctor?.doctorData?.doctorCode
              );
              navigate(`/${locales}/booking/success${searchParams}`, {
                state: {
                  ...mydata.state,
                  doctor: { doctorData: selectedDoctor },
                  clinic: { clinicData },
                },
              });
            });
        });
    } else {
      navigate(`/${locales}${path}${searchParams}`);
    }
  };

  const handleChange = (e) => {
    return handleLangaugaeClick(e.target.value);
  };

  return (
    <header className={`header`}>
      {/* Top Header */}
      <div className="top-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col d-flex justify-content-between">
              <ul className="d-flex justify-content-between align-items-center ct-md-none-l">
                <li>
                  <i className="fas fa-hand-point-up" />
                  {t("we_speak_your_language")}
                </li>
              </ul>
              <ul className="lan-main d-flex justify-content-between align-items-center">
                {localeList.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={`${
                        currentLocale === item.code ? "active" : ""
                      }`}
                      onClick={() => handleLangaugaeClick(item.code)}
                    >
                      {item.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg header-nav">
        <div className="navbar-header">
          <a href="#0" id="mobile_btn" onClick={() => onHandleMobileMenu()}>
            <span className="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>

          {searchParams.includes("source=webplugin") ? (
            <a href="JAVASCRIP:void(0);" className="navbar-brand logo">
              <img src={logo} className="img-fluid" alt="Logo" />
            </a>
          ) : (
            <Link to={`/${searchParams}`} className="navbar-brand logo">
              <img src={logo} className="img-fluid" alt="Logo" />
            </Link>
          )}

          {/* language dropdown show in small devices */}
          {width > breakpoint ? null : (
            <Form.Select
              size="sm"
              className="md-po-select-l"
              aria-label="language dropdown"
              onChange={handleChange}
            >
              {localeList.map((item, index) => {
                return (
                  <option key={index} value={item.code}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Select>
          )}
        </div>
        {source !== "webplugin" && (
          <div className="main-menu-wrapper ms-auto">
            <div className="menu-header">
              <Link to={`/${searchParams}`} className="menu-logo">
                <img src={logo} className="img-fluid" alt="Logo" />
              </Link>
              <a
                href="#0"
                id="menu_close"
                className="menu-close"
                onClick={() => onhandleCloseMenu()}
              >
                <i className="fas fa-times"></i>
              </a>
            </div>
            <ul className="main-nav">
              <li className={pathnames === "/home" ? "active" : ""}>
                <Link to={`/${searchParams}`}>{t("home")}</Link>
              </li>
              {/* <li>
                <Link to="/manage">{t("manage_booking")}</Link>
              </li>
              <li
                className={
                  router.pathname.includes("/booking/doctors") ? "active" : ""
                }
              >
                <Link to={`/${locale}/booking/doctors`}>{t("checkin")}</Link>
              </li>
              <li className="login-link" onClick={() => onhandleCloseMenu()}>
                <Link to="/">Login / Signup</Link>
              </li> */}
            </ul>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;
