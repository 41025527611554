import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Home() {
  let locale = localStorage.getItem("locale");
  let defaultLocale = locale ? locale : "en";
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("locale", defaultLocale);
  }, []);

  useEffect(() => {
    window.location.replace(`/${defaultLocale}`);
  }, []);

  return <></>;
}

export default Home;
