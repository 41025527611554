import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Spinner = () => {
  return (
    <div className="reverse-spinner"></div>
  );
};

export default Spinner;
