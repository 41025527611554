import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { dateLanguage } from "../../../lib/dateutils";

const BookingSlots = ({
  clinicId,
  clinicCode,
  slotGroup,
  selectedSlot,
  setSelectedSlot,
  page
}) => {
  const router = useLocation();
  let searchParams =
    typeof router.search != "undefined" ? `${router?.search}` : "";
  let locale = localStorage.getItem("locale");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const selectSlot = (slot) => {
    if(page !== "slot_modal"){
      navigate(
        `/${locale}/booking/slots/${clinicCode}/${slot.doctorCode}/${clinicId}/${slot.date}/${slot?.length}${searchParams}`
      );
    }
    setSelectedSlot(slot);
  };

  const rangeDateFormation = (date) => {
    let dateArr = date.split("-");
    let startDay = dateLanguage(dateArr[0], locale);
    let endDay = dateLanguage(dateArr[1], locale);
    return `${startDay} - ${endDay}`;
  };

  return (
    <div className="row m-0">
      {slotGroup?.slots?.length == 0 && (
        <div className="card appoi-not-ava appoin-date-section">
          {slotGroup ? (
            <h5 className="modal-header">
              {slotGroup?.period.includes("-")
                ? rangeDateFormation(slotGroup?.period)
                : dateLanguage(slotGroup?.period, locale)}
            </h5>
          ) : null}
          <p className="appoi-not-p">{t("no_appointment_available")}</p>
        </div>
      )}
      {slotGroup?.slots.length != 0 && (
        <div className="card booking-schedule schedule-widget appoin-date-section">
          <div className="schedule-cont">
            <div className="row m-0">
              <div className="col-md-12">
                <div className="time-slot">
                  <ul className="clearfix">
                    {slotGroup ? (
                      <h5>
                        {dateLanguage(slotGroup?.period, locale)
                          .split(",")
                          .join("")}
                        {/* {dayjs(slotGroup?.period).format("DD/MM/YYYY")} */}
                      </h5>
                    ) : null}
                    <h5>{t("morning")}</h5>
                    {slotGroup?.morningSlots &&
                    slotGroup.morningSlots.length > 0 ? (
                      slotGroup?.morningSlots.map((slot, index) => (
                        <li key={index}>
                          <div
                            className={
                              selectedSlot !== null &&
                              selectedSlot?.id === slot.id
                                ? "timing selected"
                                : "timing"
                            }
                            onClick={() => selectSlot(slot)}
                          >
                            {slot.time}
                          </div>
                        </li>
                      ))
                    ) : (
                      <div className="no-session">
                        {t("no_appointment_available")}
                      </div>
                    )}
                  </ul>
                  <ul className="clearfix">
                    <li>
                      <br />
                    </li>
                  </ul>
                  <ul className="clearfix">
                    <h5>{t("afternoon")}</h5>
                    {slotGroup?.afternoonSlots &&
                    slotGroup.afternoonSlots.length > 0 ? (
                      slotGroup?.afternoonSlots.map((slot, index) => (
                        <li key={index}>
                          <div
                            className={
                              selectedSlot !== null &&
                              selectedSlot?.id === slot.id
                                ? "timing selected"
                                : "timing"
                            }
                            onClick={() => selectSlot(slot)}
                          >
                            {slot.time}
                          </div>
                        </li>
                      ))
                    ) : (
                      <div className="no-session">
                        {t("no_appointment_available")}
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingSlots;
