import { range } from "lodash";
import {
  convertDate,
  convertTime,
  convertDateTime,
  getDateBetween,
  getEmptyPeriod,
  formatLongDate,
  isMorning,
} from "./dateutils";
import { v4 as uuidv4 } from "uuid";

export function groupSlotsByDoctor(slots) {
  let slotMap = new Map();
  for (let index = 0; index < slots.length; index++) {
    let slot = slots[index];
    let formattedDate = slot.date;
    slot.time = convertTime(formattedDate);
    slot.id = uuidv4();

    let doctorCode = slot.doctorCode;
    let slotGroup = slotMap.get(doctorCode);
    if (slotGroup === undefined) {
      slotGroup = [];
      slotGroup[0] = slot;

      slotMap.set(doctorCode, slotGroup);
    } else {
      slotGroup.push(slot);
    }
  }

  let slotGroupMap = new Map();
  slotMap.forEach((doctorSlots, doctor) => {
    if (doctorSlots.length) {
      let formattedDate = doctorSlots[0].date;
      let availableDate = convertDateTime(formattedDate);
      slotGroupMap.set(
        doctor,
        createSlotGroup(formatLongDate(availableDate), doctorSlots)
      );
    }
  });

  return slotGroupMap;
}

export function groupSlots(slots, startDate, endDate) {
  let slotMap = new Map();

  for (let index = 0; index < slots.length; index++) {
    let slot = slots[index];
    let formattedDate = slot.date;
    slot.time = convertTime(formattedDate);
    slot.id = uuidv4();
    let date = convertDate(formattedDate);
    let slotGroup = slotMap.get(date.getTime());
    if (slotGroup === undefined) {
      slotGroup = [];
      slotGroup[0] = slot;

      slotMap.set(date.getTime(), slotGroup);
    } else {
      slotGroup.push(slot);
    }
  }

  let dates = getDateBetween(startDate, endDate);
  let slotGroups = [];
  let unavailableDates = [];

  for (let date of dates) {
    let slotsOfDate = slotMap.get(date.getTime());
    if (slotsOfDate === undefined) {
      unavailableDates.push(date);
    } else {
      if (unavailableDates.length > 0) {
        let unavailableGroup = createSlotGroup(
          getEmptyPeriod(unavailableDates),
          []
        );
        slotGroups.push(unavailableGroup);

        unavailableDates = [];
      }

      let availableGroup = createSlotGroup(formatLongDate(date), slotsOfDate);
      slotGroups.push(availableGroup);
    }
  }

  return slotGroups;
}

export function getSlotGroupByDoctor(slotGroups, doctorCode) {
  return slotGroups.get(doctorCode);
}

function createSlotGroup(period, slots) {
  let availableSlot = new Object();
  availableSlot.period = period;
  availableSlot.slots = slots;
  availableSlot.morningSlots = [];
  availableSlot.afternoonSlots = [];
  for (let slot of slots) {
    if (isMorning(slot.time)) {
      availableSlot.morningSlots.push(slot);
    } else {
      availableSlot.afternoonSlots.push(slot);
    }
  }
  return availableSlot;
}

function isPatientAllowed(patientType, availableType) {
  return availableType === "*" || availableType === patientType;
}

export function isTypeAvailable(appointmentType, patientType, day) {
  if (appointmentType.availableOnline !== "Y") return false;
  if (
    !isPatientAllowed(
      patientType === "Y" ? "E" : patientType,
      appointmentType.availableTypes
    )
  ) {
    return false;
  }

  // 1-5 Mon - fri
  // 2,4 tue and thus
  // 2-5 tue - fri
  // 1,2,6 mon, tue, sat
  // * everything

  const availDay = appointmentType.availableDays;
  if (availDay === "*") {
    return true;
  }
  if (availDay.includes("-")) {
    const [start, end] = appointmentType.availableDays
      .split("-")
      .map((v) => parseInt(v.trim()));
    const daysRange = range(start, end + 1);
    return daysRange.includes(day);
  }
  if (availDay.includes(",")) {
    const daysRange = appointmentType.availableDays
      .split(",")
      .map((v) => parseInt(v.trim()));
    return daysRange.includes(day);
  }
  return parseInt(availDay) === day;
}

