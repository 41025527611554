import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./client/components/header.jsx";
import Footer from "./client/components/footer.jsx";

//pages
import Slot from "./client/components/booking/slot";
import Patient from "./client/components/booking/patient";
import Search from "./client/components/booking/search";
import Doctors from "./client/components/booking/doctor";
import BookingSuccess from "./client/components/booking/booking-success";
import Home from "./client/components/Home/index.js";
import { useTranslation } from "react-i18next";
import { PrivacyPolicy } from "./client/components/privacy-policy.jsx";
import MobileApp from "./client/components/Mobile-app/index.js";
import DownloadApp from "./client/components/DownloadApp.jsx";
import { AppContext } from "./client/components/context/AppContext.jsx";
import AboutPatient from "./client/components/bookingForm/aboutPatient.jsx";
import Account from "./client/components/account/index.jsx";

const AppRouter = (props) => {
  const { t } = useTranslation();
  const [selectorFormDetails, setSelectorFormDetails] = useState();

  const [selectedClinicData, setSelectedClinicData] = useState({});

  let locale = localStorage.getItem("locale");
  let startDate = new Date();
  let clinicCode = "DEV";
  let firstName = "Jin";
  let lastName = "Chalmer";
  let dateOfBirth = "01012000";
  let phoneNumber = "0400666888";
  let endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + 30);

  let endDateSlot = new Date(startDate);
  endDateSlot.setDate(endDateSlot.getDate() + 30);

  return (
    <AppContext.Provider
      value={{
        selectedClinicData: selectedClinicData,
        setSelectedClinicData: setSelectedClinicData,
      }}
    >
      <BrowserRouter>
        {/* <ScrollPage /> */}
        <div>
          <Header {...props} />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path=":locale/booking/slots/:clinicCode/:doctorCode/:clinicId"
              element={
                <Slot
                  startDate={startDate}
                  endDate={endDateSlot}
                  selectorFormDetails={selectorFormDetails}
                  setSelectorFormDetails={setSelectorFormDetails}
                />
              }
              exact
            />
            <Route
              path=":locale/booking/slots/:clinicCode/:doctorCode/:clinicId/:slotTime/:length"
              element={
                <Slot
                  startDate={startDate}
                  endDate={endDateSlot}
                  selectorFormDetails={selectorFormDetails}
                  setSelectorFormDetails={setSelectorFormDetails}
                />
              }
              exact
            />

            <Route
              path=":locale/manage-booking/:clinicCode/:clinicId"
              element={<AboutPatient startDate={startDate} endDate={endDate} />}
              exact
            />

            <Route
              path=":locale/account/:clinicCode/:clinicId"
              element={<Account startDate={startDate} endDate={endDate} />}
              exact
            />

            <Route
              path="/booking/patient"
              element={
                <Patient
                  clinicCode={clinicCode}
                  firstName={firstName}
                  lastName={lastName}
                  dateOfBirth={dateOfBirth}
                  phoneNumber={phoneNumber}
                />
              }
              exact
            />

            <Route path=":locale/" element={<Search />} exact />

            <Route
              path=":locale/booking/doctors/:clinicCode/:clinicId"
              element={<Doctors startDate={startDate} endDate={endDate} />}
              exact
            />
            <Route
              path=":locale/booking/doctors/:clinicCode/:clinicId/:doctorName"
              element={<Doctors startDate={startDate} endDate={endDate} />}
              exact
            />
            <Route path="/booking/yourself" element={<Patient />} exact />
            <Route
              path=":locale/booking/success"
              element={
                <BookingSuccess
                  setSelectorFormDetails={setSelectorFormDetails}
                />
              }
              exact
            />

            <Route
              path=":locale/download-app"
              element={<DownloadApp />}
              exact
            />

            <Route
              path=":locale/privacy-policy"
              element={<PrivacyPolicy />}
              exact
            />
            <Route path=":locale/mobile-app" element={<MobileApp />} exact />
          </Routes>

          <Footer {...props} />
        </div>
      </BrowserRouter>
    </AppContext.Provider>
  );
};

export default AppRouter;
