import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import IMG01 from "../../assets/images/patients/patient2.jpg";

const Patient = ({
  clinicCode,
  firstName,
  lastName,
  dateOfBirth,
  phoneNumber,
}) => {
  const [appointments, setAppointments] = useState(false);
  const [invoices, setInvoices] = useState(false);
  const [loading, setLoading] = useState(true);
  const router = useLocation();
  let locale = localStorage.getItem("locale");

  let searchParams =
    typeof router.search != "undefined" ? `${router?.search}` : "";

  React.useEffect(() => {
    setLoading(true);
    fetch(
      `/server/appointment/patient/${clinicCode}/${firstName}/${lastName}/${dateOfBirth}/${phoneNumber}`
    )
      .then((res) => res.json())
      .then((data) => setAppointments(data));
    fetch(
      `/server/invoice/patient/${clinicCode}/${firstName}/${lastName}/${dateOfBirth}/${phoneNumber}`
    )
      .then((res) => res.json())
      .then((data) => setInvoices(data));

    setLoading(false);
  }, [locale]);

  const cancelAppointment = () => {
    setLoading(true);
    let payload = {
      clinicCode: clinicCode,
      appointmentId: appointments[0].appointmentId,
    };

    fetch("/server/appointment/appointmentId", {
      method: "DELETE",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json",
      },
    }).then((res) => console.log(res.json()));

    setLoading(false);
  };

  const confirmAppointment = () => {
    setLoading(true);
    let payload = {
      clinicCode: clinicCode,
      appointmentId: appointments[0].appointmentId,
    };

    fetch("/server/appointment/confirm", {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json",
      },
    }).then((res) => console.log(res.json()));

    setLoading(false);
  };

  const checkinAppointment = () => {
    setLoading(true);
    let payload = {
      clinicCode: clinicCode,
      appointmentId: appointments[0].appointmentId,
    };

    fetch("/server/appointment/checkin", {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json",
      },
    }).then((res) => console.log(res.json()));

    setLoading(false);
  };

  const emailInvoice = () => {
    setLoading(true);
    let payload = {
      clinicCode: clinicCode,
      invoiceId: invoices[0].invoiceId,
    };

    fetch("/server/invoice/email", {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json",
      },
    }).then((res) => console.log(res.json()));

    setLoading(false);
  };

  return (
    <div className="footer-stick">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-lg-8">
              <div className="card">
                <div className="card-body">
                  <div className="card-header">
                    <h4 className="card-title">My Booking</h4>
                  </div>
                  {loading ? (
                    <div>Still loading</div>
                  ) : (
                    appointments &&
                    appointments.map((data) => (
                      <div>
                        {data.appointmentId}, {data.doctorCode},
                        {data.appointmentDate}, {data.appointmentLength}
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            <div className="submit-section mt-1">
              <Button
                onClick={cancelAppointment}
                className="btn btn-primary submit-btn"
              >
                <img src="/assets/images/doctors/search-w.png" alt="" />
                Cancel Appointment
              </Button>

              <Button
                onClick={confirmAppointment}
                className="btn btn-primary submit-btn"
              >
                <img src="/assets/images/doctors/search-w.png" alt="" />
                Confirm Appointment
              </Button>

              <Button
                onClick={checkinAppointment}
                className="btn btn-primary submit-btn"
              >
                <img src="/assets/images/doctors/search-w.png" alt="" />
                checkin Appointment
              </Button>

              <Button
                onClick={emailInvoice}
                className="btn btn-primary submit-btn"
              >
                <img src="/assets/images/doctors/search-w.png" alt="" />
                Email Invoice
              </Button>
            </div>
            <div className="col-md-5 col-lg-4 theiaStickySidebar">
              <div className="card booking-card">
                <div className="card-header">
                  <h4 className="card-title">My Booking</h4>
                </div>
                <div className="card-body">
                  <div className="booking-doc-info">
                    <Link
                      to={`/patient/doctor-profile${searchParams}`}
                      className="booking-doc-img"
                    >
                      <img src={IMG01} alt="User" />
                    </Link>
                    <div className="booking-info">
                      <h4>
                        <Link to={`/patient/doctor-profile${searchParams}`}>
                          Dr. Darren Elder
                        </Link>
                      </h4>
                      <div className="rating">
                        <i className="fas fa-star filled"></i>
                        <i className="fas fa-star filled"></i>
                        <i className="fas fa-star filled"></i>
                        <i className="fas fa-star filled"></i>
                        <i className="fas fa-star"></i>
                        <span className="d-inline-block average-rating">
                          35
                        </span>
                      </div>
                      <div className="clinic-details">
                        <p className="doc-location">
                          <i className="fas fa-map-marker-alt"></i> 50 Hutt
                          Street Adelaide
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="booking-summary">
                    <div className="booking-item-wrap">
                      <ul className="booking-date">
                        <li>
                          Date <span>16 Nov 2019</span>
                        </li>
                        <li>
                          Time <span>10:00 AM</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Patient;
