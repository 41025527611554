import React, { useEffect } from "react";

const MobileApp = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      // Redirect to Google Play Store for Android devices
      window.location.href =
        "https://play.google.com/store/apps/details?id=au.com.nextappointment.android";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Redirect to Apple App Store for iOS devices
      window.location.href =
        "https://apps.apple.com/us/app/next-appointment/id6450647230";
    } else {
      // Redirect to a webpage if the device is not Android or iOS
      window.location.href = "https://nextappointment.com.au/";
    }
  }, []);

  return <></>;
};

export default MobileApp;
