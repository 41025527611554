import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = (props) => {
  const { t } = useTranslation();
  let locale = localStorage.getItem("locale");

  return (
    <footer className="footer">
      <div className="footer-top text-lg-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-widget footer-about">
                <h2 className="footer-title">{t("help_and_support")}</h2>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="footer-widget footer-menu">
                <h2 className="footer-title">
                <Link to={`/${locale}/privacy-policy`} className="text-white">{t("privacy_and_policy")}</Link>
                </h2>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="footer-widget footer-menu">
                <h2 className="footer-title">{t("term_of_use")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom text-center">
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-12">
                <div className="copyright-text">
                  <p className="mb-0">
                    &copy; {t("all_right_reserved")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
