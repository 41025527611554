import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import {
  convertDate,
  convertTime,
  dateLanguage,
  formatDateString,
  longDateFormat,
} from "../../lib/dateutils.js";

const BookingSuccess = ({ setSelectorFormDetails }) => {
  const { t } = useTranslation();
  let locale = localStorage.getItem("locale");
  let mydata = useLocation();
  let searchParams =
    typeof mydata.search != "undefined" ? `${mydata?.search}` : "";
  let doctorDetails = mydata.state.doctor.doctorData;
  let clinicData = mydata.state.clinic.clinicData;
  let slotTime = mydata.state.appointment.slotTime;

  useEffect(() => {
    if (clinicData) {
      setSelectorFormDetails();
    }
  }, [clinicData]);

  return (
    <section className="content success-page-cont footer-stick">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="card success-card">
              <div className="card-body">
                <div className="success-cont">
                  <h1>{t("appointment_confirmed")}</h1>
                  <div className="doctor-detail">
                    <div className="doctor-detail-left">
                      <img
                        src={
                          `/assets/images/doctors/` +
                          `${doctorDetails?.doctorImg}`
                        }
                        className="img-fluid"
                        alt="User"
                      />
                    </div>
                    <div className="doctor-detail-right">
                      <h3>{doctorDetails?.doctorName}</h3>
                      <p>
                        <span>
                          <img
                            src="/assets/images/doctors/clock-icon.png"
                            alt=""
                          />
                        </span>
                        <span>
                          {dateLanguage(
                            longDateFormat(convertDate(slotTime).toString()),
                            locale
                          )}
                          {/* {formatDateString(slotTime)} */}
                          {" " + t("at") + " "}
                          {convertTime(slotTime)}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="main-details-block">
                    <div className="main-details-block-container">
                      <div className="details">
                        <img
                          src={`/assets/images/doctors/plus-icon.png`}
                          alt=""
                        />
                        <h4>{clinicData?.clinicName}</h4>
                      </div>
                      <div className="details">
                        <img
                          src="/assets/images/doctors/location-icon.png"
                          alt=""
                        />
                        <h4>{clinicData?.address}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <Link
                  to={`/${locale}${searchParams}`}
                  className="btn ct-apt-btn border m-auto"
                >
                  {t("close")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookingSuccess;
