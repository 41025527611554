import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const DownloadApp = ({ app, step }) => {
  let locale = localStorage.getItem("locale");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDownloadApp, setShowDownloadApp] = useState(false);

  const onClickDowanloadApp = () => {
    // setShowDownloadApp(true);
    navigate(`/${locale}/download-app`);
  };

  return (
    <>
      {app === "home" ? (
        <>
          {}
          <section className="book-you-next-section">
            <div className="book-you-next-inner">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className={`book-you-next-left d-main-section ${step === 1 ? "mt-spacing" : ""}`}>
                      <div className="book-you-next-header">
                        <h6>{t("dowanload_app_book_your_next")}</h6>
                        <h2>
                          {t("dowanload_app_appointment")}{" "}
                          {t("dowanload_app_main_app")}
                        </h2>
                      </div>
                      <Button onClick={onClickDowanloadApp}>
                        {t("dowanload_app_button")}
                      </Button>
                      <div className="book-you-next-store-button">
                        <Button className="btn" href="https://apps.apple.com/us/app/next-appointment/id6450647230" target="blank">
                          <img
                            src="/assets/images/app-store-button.png"
                            alt="NextAppointment mobile application"
                          />
                        </Button>
                        <Button className="btn" href="https://play.google.com/store/apps/details?id=au.com.nextappointment.android" target="blank">
                          <img
                            src="/assets/images/google-play-button.png"
                            alt="NextAppointment mobile application"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="book-you-next-img">
                <img src="/assets/images/book-you-next-img.png" alt="" />
              </div>
            </div>
          </section>
        </>
      ) : (
        <div className="">
          <div className="footer-stick">
            <div className="container">
              <div className="row h-100 align-items-center footer-padding">
                <div className="col-lg-6 footer-stick-margin">
                  <div className="search-header">
                    <h3 className="text-black">
                      {t("dowanload_app_main_book_your_favorite")}
                      {t("doanload_app_main_pactitioners")}
                      <span className="download-app-appoinment text-blue">
                        {t("dowanload_app_appointment")}
                      </span>
                      {t("dowanload_app_main_app")} <br />
                      <span className="available-text">
                        {t("scan_and_dowanload_app")}
                      </span>
                    </h3>
                  </div>

                  <img
                    src="/assets/images/next.appointment.QR.code.png"
                    className="ap-qr-img"
                    alt="QR Code NextAppointment mobile application"
                  />
                  <div className="mt-4">
                    <Button className="me-4" href="https://apps.apple.com/us/app/next-appointment/id6450647230" target="blank">
                      <img src="/assets/images/app-store-btn.png" alt="NextAppointment mobile application" />
                    </Button>
                    <Button className="" href="https://play.google.com/store/apps/details?id=au.com.nextappointment.android" target="blank">
                      <img src="/assets/images/google-play-button.png" alt="NextAppointment mobile application" />
                    </Button>
                  </div>
                </div>
                <div className="col-lg-6 d-flex align-items-end">
                  <img
                    src="/assets/images/search-img-fa-img.png"
                    alt=""
                    className="search-img-fa"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DownloadApp;
