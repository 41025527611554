import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import AboutPatientForm from "./aboutPatientForm";

function BookingForm({
  bookingDetails,
  setBookingDetails,
  validated,
  handleSubmit,
  birthdate,
  setBirthDate,
  handlePostCodeChange,
  searchDetails,
  promiseAddressOptions,
  handleDateChange,
  bDate,
}) {
  const _ = require("lodash");
  const { t } = useTranslation();
  const [valLength, setValLength] = useState();
  const onValueChange = (e) => {
    setBookingDetails({
      ...bookingDetails,
      [e.target.name]: e.target.value,
    });
  };

  const onInputChange = (e) => {
    setValLength(e);
  };

  let dateOptions = [];
  dateOptions = _.range(1, 32).map((i) => ({ value: `${i}`, label: `${i}` }));

  let monthOptions = [
    { value: "01", label: t("january") },
    { value: "02", label: t("february") },
    { value: "03", label: t("march") },
    { value: "04", label: t("april") },
    { value: "05", label: t("may") },
    { value: "06", label: t("june") },
    { value: "07", label: t("july") },
    { value: "08", label: t("august") },
    { value: "09", label: t("september") },
    { value: "10", label: t("october") },
    { value: "11", label: t("november") },
    { value: "12", label: t("december") },
  ];

  let yearOptions = [];
  yearOptions = _.range(new Date().getFullYear(), 1930 - 1, -1).map((i) => ({
    value: `${i}`,
    label: `${i}`,
  }));

  if (bDate?.day !== "" && bDate?.month !== "" && bDate?.year !== "") {
    const mm = monthOptions?.filter((i) => i?.label === bDate?.month);
    if (bDate?.day?.length === 1) {
      setBirthDate(`0${bDate?.day}/${mm[0]?.value}/${bDate?.year}`);
    } else {
      setBirthDate(`${bDate?.day}/${mm[0]?.value}/${bDate?.year}`);
    }
  }

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <div className="info-widget">
        <h3>{t("about_you")}</h3>
        <AboutPatientForm
          onValueChange={onValueChange}
          bookingDetails={bookingDetails}
          bDate={bDate}
          dateOptions={dateOptions}
          monthOptions={monthOptions}
          yearOptions={yearOptions}
          handleDateChange={handleDateChange}
          validated={validated}
          birthdate={birthdate}
        />
      </div>

      <div className="info-widget">
        <h3>{t("address")}</h3>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group card-label">
              <Form.Label htmlFor="card_name">
                {t("street_address")} <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                className="form-control"
                onChange={onValueChange}
                value={bookingDetails.streetAddress}
                name="streetAddress"
                type="text"
                required
              />
              <Form.Control.Feedback type="invalid">
                {t("street_address_err_message")}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group card-label book-form-input">
              <Form.Label htmlFor="card_number">
                {t("suburb")} <span className="text-danger">*</span>
              </Form.Label>
              <AsyncSelect
                cacheOptions
                placeholder={t("select_option")}
                name="postcode"
                className={
                  (valLength?.length || searchDetails?.postcode) && "filled"
                }
                value={searchDetails?.postcode}
                onChange={handlePostCodeChange}
                loadOptions={promiseAddressOptions}
                onInputChange={onInputChange}
                required
              />
              {!searchDetails?.postcode && validated && (
                <p className="invalid-feedback d-block">
                  {t("suburb_err_message")}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-12 mb-3">
            {searchDetails?.postcode ? (
              <div className="row">
                <div className="col-md-6">
                  <Form.Label>{t("state")}</Form.Label>
                  <Form.Control
                    className="form-control"
                    value={searchDetails?.postcode?.state}
                    name="state"
                    type="text"
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <Form.Label>{t("postcode")}</Form.Label>
                  <Form.Control
                    className="form-control"
                    value={searchDetails?.postcode?.postcode}
                    name="postcode"
                    type="text"
                    disabled
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="terms-accept">
          <div className="custom-checkbox">
            <Form.Check
              required
              name="termsAccept"
              type="checkbox"
              id="terms_accept"
              label={t("read_and_accept_term_and_condition")}
              feedback={t("read_and_accept_term_and_condition_feedback")}
              feedbackType="invalid"
            />
            <Form.Control.Feedback type="invalid">
              {t("accept_term_and_condition")}
            </Form.Control.Feedback>
          </div>
        </div>

        <div className="submit-section proceed-btn text-end m-0">
          <button type="submit" className="ct-apt-btn ms-auto">
            {t("continue")}
          </button>
        </div>
      </div>
    </Form>
  );
}

export default BookingForm;
