import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import isUndefined from "lodash/isUndefined";
import { useTranslation } from "react-i18next";

const _ = { isUndefined };

function AlertModal(props) {
  const { t } = useTranslation();
  const { modalStatus, title, message, closeCallback, resStatus } = props;

  let cls = "";
  if (!_.isUndefined(resStatus)) {
    if (resStatus === "error") {
      cls = "res-error";
    } else if (resStatus === "success") {
      cls = "res-success";
    }
  }

  return (
    <Modal show={modalStatus} onHide={() => closeCallback(false)}>
      <Modal.Header closeButton>
        {!_.isUndefined(title) && (
          <Modal.Title className={cls}>{title}</Modal.Title>
        )}
      </Modal.Header>

      <Modal.Body>
        <div className={`modal-body ${cls}`}>{message}</div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          className={`${cls}`}
          variant="secondary"
          onClick={() => closeCallback(false)}
        >
          {t("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AlertModal;
