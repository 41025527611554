import React from "react";
import ReactDOM from "react-dom/client";
import AppRouter from "./approuter";
// boostrap
// import "./client/assets/js/bootstrap.bundle.min.js";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "bootstrap/dist/css/bootstrap.min.css";
//fontawesome
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "react-datepicker/dist/react-datepicker.css";
//carousel
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import "./i18n";

require("./client/assets/css/all.css");
require("./client/assets/css/all.min.css");
require("./client/assets/css/fontawesome.min.css");
require("./client/assets/css/style.css");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AppRouter />);
