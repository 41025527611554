import React, { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import BookingSlots from "./shared/slot-group.jsx";
import Spinner from "./shared/spinner.jsx";
import { formatDate } from "../../lib/dateutils.js";
import { groupSlotsByDoctor } from "../../lib/slotutils.js";
import { useTranslation } from "react-i18next";

const DoctorList = ({ startDate, endDate }) => {
  const [doctorListData, setDoctorListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { clinicCode, doctorName, clinicId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  let locale = localStorage.getItem("locale");
  let formattedStart = formatDate(startDate);
  let formattedEnd = formatDate(endDate);
  const router = useLocation();
  let searchParams =
    typeof router.search != "undefined" ? `${router?.search}` : "";

  React.useEffect(() => {
    setLoading(true);
    fetch(`/server/practitioner/list/${clinicId}/${locale}`)
      .then((res) => res.json())
      .then((data) =>
        fetch(
          `/server/appointment/firstavailableday/${clinicCode}/${formattedStart}/${formattedEnd}`
        )
          .then((res) => res.json())
          .then((resp) => groupSlotsByDoctor(resp))
          .then((appointment) => {
            setDoctorListData(
              data.map((item) => ({ ...item, slotGroups: appointment }))
            );
            setLoading(false);
          })
          .catch((err) => {
            setDoctorListData(data);
            setLoading(false);
          })
      );
  }, [locale]);

  const handleMoreAppointment = (data) => {
    navigate(
      `/${locale}/booking/slots/${clinicCode}/${data.doctorCode}/${clinicId}${searchParams}`
    );
  };

  return (
    <div className="main-body footer-stick">
      {loading ? (
        <div className="main-loader">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="appoi-nav-main back-button-container py-4">
            <div className="appoi-nav-inner-main">
              <button
                onClick={() => navigate(`/${locale}/`)}
                className="btn back-button"
              >
                <i className="fas fa-arrow-left filled"></i>
                <span>{t("back")}</span>
              </button>
            </div>
          </div>
          {doctorListData &&
            doctorListData.map((data, index) => {
              return data.doctorName === doctorName ? (
                <div key={index} className="card">
                  <div className="card-body">
                    <div className="doctor-widget">
                      <div className="doc-info-left">
                        <div className="doctor-img">
                          {/* <Link to={`/patient/doctor-profile${searchParams}`}> */}
                          <img
                            src={`/assets/images/doctors/${data?.doctorImg}`}
                            className="img-fluid"
                            alt="User"
                          />
                          {/* </Link> */}
                        </div>
                        <div className="doc-info-cont">
                          <h4 className="doc-name">
                            {/* <Link to={`/patient/doctor-profile${searchParams}`}> */}
                            {data?.doctorName}
                            {/* </Link> */}
                          </h4>
                          <p className="doc-speciality">
                            {data?.professionalTitles}
                          </p>
                          <h5 className="doc-department">
                            {t("speak")}
                            {data?.doctorLanguages}
                          </h5>
                          <div className="clinic-details">
                            <p className="doc-location">
                              {data?.docDescription}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="doc-info-right">
                        <div></div>
                        <div className="card booking-schedule schedule-widget border-0">
                          <div>
                            {loading ? (
                              <Spinner />
                            ) : (
                              <BookingSlots
                                clinicId={clinicId}
                                clinicCode={clinicCode}
                                slotGroup={data?.slotGroups?.get(
                                  Number(data?.doctorCode)
                                )}
                              />
                            )}
                          </div>
                        </div>
                        <div className="clinic-booking">
                          <div
                            onClick={() => handleMoreAppointment(data)}
                            className="ct-apt-btn"
                          >
                            {t("more_appointment")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null;
            })}
          {doctorListData &&
            doctorListData.map((data, index) => {
              return data.doctorName !== doctorName ? (
                <div key={index} className="card">
                  <div className="card-body">
                    <div className="doctor-widget">
                      <div className="doc-info-left">
                        <div className="doctor-img">
                          {/* <Link to={`/patient/doctor-profile${searchParams}`}> */}
                          <img
                            src={`/assets/images/doctors/${data?.doctorImg}`}
                            className="img-fluid"
                            alt="User"
                          />
                          {/* </Link> */}
                        </div>
                        <div className="doc-info-cont">
                          <h4 className="doc-name">
                            {/* <Link to={`/patient/doctor-profile${searchParams}`}> */}
                            {data?.doctorName}
                            {/* </Link> */}
                          </h4>
                          <p className="doc-speciality">
                            {data?.professionalTitles}
                          </p>
                          <h5 className="doc-department">
                            {t("speak")}
                            {data?.doctorLanguages}
                          </h5>
                          <div className="clinic-details">
                            <p className="doc-location">
                              {data?.docDescription}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="doc-info-right">
                        <div></div>
                        <div className="card booking-schedule schedule-widget border-0">
                          <div>
                            {loading ? (
                              <Spinner />
                            ) : (
                              <BookingSlots
                                clinicId={clinicId}
                                clinicCode={clinicCode}
                                slotGroup={data?.slotGroups?.get(
                                  Number(data?.doctorCode)
                                )}
                              />
                            )}
                          </div>
                        </div>
                        <div className="clinic-booking">
                          <div
                            onClick={() => handleMoreAppointment(data)}
                            className="ct-apt-btn"
                          >
                            {t("more_appointment")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null;
            })}
        </>
      )}
    </div>
  );
};

export default DoctorList;
