import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const UnavailableTooltip = ({ index, children }) => {
  const { t } = useTranslation();
  return (
    <OverlayTrigger
      placement="bottom"
      trigger={["hover", "hover"]}
      overlay={
        <Tooltip id={`button-tooltip-${index}`}>
          {t("unavailable_tooltip")}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default UnavailableTooltip;
