import React from "react";
import { useTranslation } from "react-i18next";

export const PrivacyPolicy = () => {
  let locale = localStorage.getItem("locale");
  const { t } = useTranslation();
  return (
    <>
      <div className="privacy-page-main">
        <div className="container my-5">
          <div>
            <h3 className="text-center my-3">{t("privacy_policy")}</h3>
          </div>

          <div>
            <h5>{t("p_intro")}</h5>
            <p>{t("p_introduction")}</p>
          </div>

          <div>
            <h5>{t("p_information")}</h5>
            <p  className="mb-2">{t("p_info_collect")}</p>
            <ul>
              <li>{t("your_name")}</li>
              <li>{t("your_DOB")}</li>
              <li>{t("your_contact_detail")}</li>
              <li>{t("your_gender")}</li>
              <li>{t("occupation")}</li>
              <li>{t("cultural_bg")}</li>
              <li>{t("your_emg_detail")}</li>
              <li>{t("your_kin_detail")}</li>
              <li>{t("your_app_request")}</li>
              <li>{t("your_app_reason")}</li>
              <li>{t("your_notes")}</li>
              <li>{t("your_health")}</li>
              <li>{t("your_medicare")}</li>
            </ul>
          </div>

          <div>
            <h5>{t("q_anonymous")}</h5>
            <p>{t("ans_anonymous")}</p>
          </div>

          <div>
            <h5>{t("ps_info")}</h5>
            <p>{t("ans_info")}</p>
          </div>

          <div>
            <h5>{t("ps_share_info")}</h5>
            <p>{t("ans_share_info")}</p>
          </div>

          <div>
            <h5>{t("ps_protect")}</h5>
            <p>{t("ans_protect")}</p>
          </div>

          <div>
            <h5>{t("your_complaint")}</h5>
            <p>
              {t("ans_complaint_1")}
              <a href="mailto:admin@nextappointment.com.au" className="">
                admin@nextappointment.com.au
              </a>
              {t("ans_complaint_2")}
            </p>
            <p>
              {t("contact_info_1")}{" "}
              <a href="www.oaic.gov.au" target="_blank">www.oaic.gov.au </a>
              {t("contact_info_2")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
