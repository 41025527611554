import React, { useCallback, useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import Slider from "react-slick";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Spinner from "./shared/spinner";
import { useTranslation } from "react-i18next";
import DownloadApp from "../DownloadApp";
import IMG1 from "../../assets/images/general_practice.svg";
import IMG2 from "../../assets/images/dentistry.svg";
import IMG3 from "../../assets/images/skin_cancer.svg";
import IMG4 from "../../assets/images/physiotherapy.svg";

var settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: (
    <img src="assets/images/slider-arrow-img-right.png" class="prev-button" />
  ),
  prevArrow: <img src="assets/images/slider-arrow-img-right.png" />,
  responsive: [
    {
      breakpoint: 636,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const Search = () => {
  let locale = localStorage.getItem("locale");
  const navigate = useNavigate();
  const [serviceOpt, setServiceOpt] = useState([]);
  const [popularClinic, setPopularClinic] = useState([]);
  const [clinic, setClinic] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchDetails, setSearchDetails] = useState({
    postcode: null,
    practitioner: null,
  });
  const [onInputChange, setOnInputChange] = useState(true);
  const [step, setStep] = useState(1);
  const { t } = useTranslation();
  const router = useLocation();

  useEffect(() => {
    setLoading(true);
    fetch(`/server/clinic/popular/${locale}`)
      .then((res) => res.json())
      .then((data) => {
        setPopularClinic(data);
        setLoading(false);
      });
    fetch(`/server/lookup/speciality/${locale}`)
      .then((res) => res.json())
      .then((data) => {
        let serviceData = data.map((item) => ({
          value: item.specialityCode,
          label: item.shortDescription,
        }));
        setServiceOpt(serviceData);
      });
  }, [locale]);

  const handlePractitionerChange = (selected) => {
    // console.log(
    //   "selected===>",
    //   searchDetails?.practitioner?.value,
    //   selected?.value,
    //   serviceOpt.find((i) => i.label === selected?.label)
    // );

    if (serviceOpt.find((i) => i.label === selected?.label)) {
      setStep(2);
    }
    setOnInputChange(true);
    setClinic([]);
    setDoctors([]);
    setSearchDetails({ ...searchDetails, practitioner: selected });
  };

  const handlePostCodeChange = (selected) => {
    setSearchDetails({
      ...searchDetails,
      postcode: { ...selected, label: selected.label.split(",")[0] },
    });
  };

  const handleSelectClinic = (item) => {
    setStep(2);
    setSearchDetails({ ...searchDetails, practitioner: item });
  };

  const getPostCode = async (inputText) => {
    return await fetch(`/server/lookup/postcode/${inputText}`)
      .then((res) => res.json())
      .then((data) => {
        return data.map((item) => ({
          value: item.id,
          label: `${item.suburb}, ${item.state}, ${item.postcode}`,
          state: item.state,
          postcode: item.postcode,
        }));
      });
  };

  const promiseAddressOptions = useCallback(
    debounce((inputValue, callback) => {
      getPostCode(inputValue).then((options) => callback(options));
    }, 1000),
    []
  );

  const handleSearch = () => {
    setClinic([]);
    setDoctors([]);

    if (searchDetails?.practitioner) {
      setOnInputChange(false);

      if (searchDetails?.practitioner?.__isNew__) {
        fetch(
          `/server/practitioner/search/${searchDetails?.practitioner?.value}/${
            searchDetails?.postcode?.value ? searchDetails?.postcode?.value : 0
          }/${locale}`
        )
          .then((res) => res.json())
          .then((data) => {
            setDoctors(data);
            if (data?.length) {
              setStep(2);
            }
          });
      } else {
        fetch(
          `/server/clinic/search/${searchDetails?.practitioner?.value}/${
            searchDetails?.postcode?.value ? searchDetails?.postcode?.value : 0
          }/${locale}`
        )
          .then((res) => res.json())
          .then((data) => {
            setClinic(data);
          });
      }
    }
  };

  const filterColors = (inputValue) => {
    return serviceOpt.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterColors(inputValue));
      }, 1000);
    });

  const handleClinicClick = (item) => {
    let searchParams =
      typeof router.search != "undefined" ? `${router?.search}` : "";
    navigate(
      `/${locale}/booking/doctors/${item.clinicCode}/${item.clinicId}${searchParams}`
    );
  };

  const handleDoctorClick = (item) => {
    let searchParams =
      typeof router.search != "undefined" ? `${router?.search}` : "";
    navigate(
      `/${locale}/booking/doctors/${item.clinicCode}/${item.clinicId}/${item.knownAs}${searchParams}`
    );
  };

  const handleManageAppointment = (item) => {
    // let searchParams =
    //   typeof router.search != "undefined" ? `${router?.search}` : "";
    navigate(`/${locale}/manage-booking/${item.clinicCode}/${item.clinicId}`);
  };

  const handleAccount = (item) => {
    navigate(`/${locale}/account/${item.clinicCode}/${item.clinicId}`);
  };

  return (
    <>
      <div className="main-search-section footer-stick">
        {step === 1 && (
          <div className="search-banner">
            <div className="search-inner-banner position-relative">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="container my-5 main-book-app-main-section">
                      {/* <h3>{t("book_appointment_now")}</h3> */}
                      <div className="search-header">
                        <h2>
                          {t("book_your_next")} <br />
                          {t("healthcare_appointment")}
                        </h2>
                        <p>{t("find_pactitioners")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex align-items-end">
                    <img
                      src="/assets/images/search-img-fa-img.png"
                      alt=""
                      className="search-img-fa"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-10 col-12">
                    <div className="book-app-main-section">
                      <div className="row">
                        {serviceOpt?.map((i, index) => (
                          <div
                            key={index}
                            className="col-xl-3 col-md-6 card-margin"
                            onClick={() => {
                              handleSelectClinic(i);
                              setStep(2);
                            }}
                          >
                            <Card className={`cursor-pointer `}>
                              <Card.Img
                                variant="top"
                                src={
                                  i?.value === "GP"
                                    ? IMG1
                                    : i?.value === "DENTAL"
                                    ? IMG2
                                    : i?.value === "SKINCANCER"
                                    ? IMG3
                                    : i?.value === "PHYSIO"
                                    ? IMG4
                                    : ""
                                }
                              />
                              <Card.Body>
                                <Card.Title>{i?.label}</Card.Title>
                              </Card.Body>
                            </Card>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          className={`content container-fluid ${
            step === 1 ? "card-padding" : ""
          }`}
        >
          <div className="row">
            <section className="doctor-search-section">
              <div className="container">
                <div className="row align-items-center">
                  <div className="">
                    <div className="doctor-search">
                      <div className="doctor-form">
                        {clinic?.length ? (
                          <div className="main-popular-inner-box">
                            {searchDetails?.practitioner?.value &&
                            step === 2 ? (
                              <>
                                <div className="appoi-nav-main back-button-container pt-0 px-0">
                                  <div className="appoi-nav-inner-main">
                                    <Link to={`/`} className="btn back-button">
                                      <i className="fas fa-arrow-left filled"></i>
                                      <span>{t("back")}</span>
                                    </Link>
                                  </div>
                                </div>
                                <div className="mb-4">
                                  <h3 className="Pract-title">
                                    {
                                      serviceOpt?.filter(
                                        (i) =>
                                          searchDetails?.practitioner?.value ===
                                          i.value
                                      )[0]?.label
                                    }
                                  </h3>
                                </div>
                              </>
                            ) : (
                              <h3 className="Pract-title Pract-title-margin">
                                {t("practices")}
                              </h3>
                            )}

                            <div className="">
                              {clinic.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    // onClick={() => handleClinicClick(item)}
                                    className="card-practices-box"
                                  >
                                    <div className="d-flex align-items-center img-content-c">
                                      <div className="card-pra-img">
                                        <img
                                          src={`/assets/images/clinics/${item.logoFile}`}
                                          className="img-fluid"
                                          alt="img"
                                        />
                                      </div>
                                      <h5 className="search-card-title">
                                        {item.clinicName}
                                      </h5>
                                    </div>
                                    <div className="search-result">
                                      <ul>
                                        <li>
                                          <span>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={14}
                                              height={14}
                                              fill="none"
                                            >
                                              <g
                                                fill="currentColor"
                                                clipPath="url(#a)"
                                              >
                                                <path d="M7 3.5a2.333 2.333 0 1 0 0 4.667A2.333 2.333 0 0 0 7 3.5ZM7 7a1.167 1.167 0 1 1 0-2.333A1.167 1.167 0 0 1 7 7Z" />
                                                <path d="M7 14a3.075 3.075 0 0 1-2.515-1.283c-2.223-3.067-3.35-5.372-3.35-6.853a5.865 5.865 0 0 1 11.73 0c0 1.48-1.127 3.786-3.35 6.853A3.075 3.075 0 0 1 7 14ZM7 1.272a4.598 4.598 0 0 0-4.593 4.594c0 1.172 1.104 3.34 3.109 6.105a1.835 1.835 0 0 0 2.969 0c2.004-2.765 3.108-4.933 3.108-6.105A4.598 4.598 0 0 0 7 1.272Z" />
                                              </g>
                                              <defs>
                                                <clipPath id="a">
                                                  <path
                                                    fill="#fff"
                                                    d="M0 0h14v14H0z"
                                                  />
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </span>
                                          <p>{item.address}</p>
                                        </li>
                                        <li>
                                          <span>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={14}
                                              height={14}
                                              fill="none"
                                            >
                                              <g clipPath="url(#a)">
                                                <path
                                                  fill="currentColor"
                                                  d="M7.583.583A.583.583 0 0 1 8.167 0 5.84 5.84 0 0 1 14 5.833a.583.583 0 1 1-1.166 0 4.672 4.672 0 0 0-4.667-4.666.583.583 0 0 1-.584-.584ZM8.167 3.5A2.333 2.333 0 0 1 10.5 5.833a.583.583 0 0 0 1.167 0 3.504 3.504 0 0 0-3.5-3.5.583.583 0 0 0 0 1.167Zm5.304 6.264a1.809 1.809 0 0 1 0 2.554l-.53.612C8.162 17.504-3.464 5.881 1.04 1.088l.67-.583a1.797 1.797 0 0 1 2.524.023c.018.019 1.1 1.423 1.1 1.423a1.808 1.808 0 0 1-.005 2.497l-.675.85a7.456 7.456 0 0 0 4.043 4.051l.854-.68a1.808 1.808 0 0 1 2.498-.003s1.403 1.08 1.421 1.098Zm-.803.849s-1.395-1.074-1.414-1.092a.642.642 0 0 0-.903 0c-.016.016-1.193.953-1.193.953a.583.583 0 0 1-.57.089 8.756 8.756 0 0 1-5.146-5.138.583.583 0 0 1 .084-.583s.938-1.178.954-1.193a.642.642 0 0 0 0-.903C4.46 2.728 3.388 1.33 3.388 1.33a.642.642 0 0 0-.881.023l-.671.583c-3.291 3.958 6.783 13.474 10.251 10.196l.532-.612a.654.654 0 0 0 .05-.909Z"
                                                />
                                              </g>
                                              <defs>
                                                <clipPath id="a">
                                                  <path
                                                    fill="#fff"
                                                    d="M0 0h14v14H0z"
                                                  />
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </span>
                                          <p>{item.phoneNumber}</p>
                                        </li>
                                      </ul>
                                      <p>{item.profile}</p>
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap main-button-section">
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={() => handleClinicClick(item)}
                                      >
                                        {t("book_appointment")}
                                      </button>
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={() =>
                                          handleManageAppointment(item)
                                        }
                                      >
                                        {t("manage_appointment")}
                                      </button>
                                      <button
                                        className="btn btn-outline-primary"
                                        onClick={() => handleAccount(item)}
                                      >
                                        {t("account")}
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : null}
                        {doctors?.length ? (
                          <div>
                            <div className="appoi-nav-main back-button-container pt-0 px-0">
                              <div className="appoi-nav-inner-main">
                                <Link to={`/`} className="btn back-button">
                                  <i className="fas fa-arrow-left filled"></i>
                                  <span>{t("back")}</span>
                                </Link>
                              </div>
                            </div>
                            <div className="mb-4">
                              <h3 className="Pract-title">
                                {t("practitioners")}
                              </h3>
                            </div>
                            <div className="">
                              {doctors.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="card-practices-box"
                                    onClick={() => handleDoctorClick(item)}
                                  >
                                    <div className="d-flex align-items-center img-content-c">
                                      <div className="card-pra-img">
                                        <img
                                          src={
                                            `/assets/images/doctors/` +
                                            `${item.imageFile}`
                                          }
                                          className="img-fluid"
                                          alt="img"
                                        />
                                      </div>
                                      <h5 className="search-card-title">
                                        {item.knownAs}
                                      </h5>
                                    </div>
                                    <div className="search-result">
                                      <p>{item.clinicName}</p>
                                      <p>{item.address}</p>
                                      <p>{item.phoneNumber}</p>
                                      <p>
                                        <span>{item.shortProfile}</span>
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : null}
                        {
                          // searchDetails?.practitioner === null &&
                          // searchDetails?.postcode === null &&
                          onInputChange ? (
                            <div className="main-popular-section">
                              {loading ? (
                                <div className="main-loader">
                                  <Spinner />
                                </div>
                              ) : (
                                <>
                                  {searchDetails?.practitioner?.value &&
                                  step === 2 ? (
                                    <>
                                      <div className="appoi-nav-main back-button-container pt-0 px-0">
                                        <div className="appoi-nav-inner-main">
                                          <Link
                                            to={`/`}
                                            className="btn back-button"
                                          >
                                            <i className="fas fa-arrow-left filled"></i>
                                            <span>{t("back")}</span>
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="mb-4">
                                        <h3 className="Pract-title">
                                          {
                                            serviceOpt?.filter(
                                              (i) =>
                                                searchDetails?.practitioner
                                                  ?.value === i.value
                                            )[0]?.label
                                          }
                                        </h3>
                                      </div>
                                    </>
                                  ) : step === 2 ? (
                                    <div className="d-flex align-items-center Pract-title-margin">
                                      <Link to={"/"}>
                                        <img
                                          src="/assets/images/back-arrow.png"
                                          alt=""
                                          className="me-2 back-btn"
                                        />
                                      </Link>
                                      <h3 className="Pract-title">
                                        {t("popular_practices")}
                                      </h3>
                                    </div>
                                  ) : (
                                    <h3 className="Pract-title title-spacing">
                                      {t("popular_practices")}
                                    </h3>
                                  )}
                                  <div className="main-popular-inner-box">
                                    {popularClinic.map((item, index) => {
                                      if (
                                        typeof searchDetails?.practitioner
                                          ?.value !== "undefined" &&
                                        item?.clinicType !==
                                          searchDetails?.practitioner?.value
                                      ) {
                                        return null;
                                      }

                                      return (
                                        <div
                                          key={index}
                                          className="card-practices-box"
                                        >
                                          <div className="d-flex align-items-center img-content-c">
                                            <div className="card-pra-img">
                                              <img
                                                src={`/assets/images/clinics/${item.logoFile}`}
                                                className="img-fluid"
                                                alt="img"
                                              />
                                            </div>
                                            <h5 className="search-card-title">
                                              {item.clinicName}
                                            </h5>
                                          </div>
                                          <div className="search-result">
                                            <ul>
                                              <li>
                                                <span>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={14}
                                                    height={14}
                                                    fill="none"
                                                  >
                                                    <g
                                                      fill="currentColor"
                                                      clipPath="url(#a)"
                                                    >
                                                      <path d="M7 3.5a2.333 2.333 0 1 0 0 4.667A2.333 2.333 0 0 0 7 3.5ZM7 7a1.167 1.167 0 1 1 0-2.333A1.167 1.167 0 0 1 7 7Z" />
                                                      <path d="M7 14a3.075 3.075 0 0 1-2.515-1.283c-2.223-3.067-3.35-5.372-3.35-6.853a5.865 5.865 0 0 1 11.73 0c0 1.48-1.127 3.786-3.35 6.853A3.075 3.075 0 0 1 7 14ZM7 1.272a4.598 4.598 0 0 0-4.593 4.594c0 1.172 1.104 3.34 3.109 6.105a1.835 1.835 0 0 0 2.969 0c2.004-2.765 3.108-4.933 3.108-6.105A4.598 4.598 0 0 0 7 1.272Z" />
                                                    </g>
                                                    <defs>
                                                      <clipPath id="a">
                                                        <path
                                                          fill="#fff"
                                                          d="M0 0h14v14H0z"
                                                        />
                                                      </clipPath>
                                                    </defs>
                                                  </svg>
                                                </span>
                                                <p>{item.address}</p>
                                              </li>
                                              <li>
                                                <span>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={14}
                                                    height={14}
                                                    fill="none"
                                                  >
                                                    <g clipPath="url(#a)">
                                                      <path
                                                        fill="currentColor"
                                                        d="M7.583.583A.583.583 0 0 1 8.167 0 5.84 5.84 0 0 1 14 5.833a.583.583 0 1 1-1.166 0 4.672 4.672 0 0 0-4.667-4.666.583.583 0 0 1-.584-.584ZM8.167 3.5A2.333 2.333 0 0 1 10.5 5.833a.583.583 0 0 0 1.167 0 3.504 3.504 0 0 0-3.5-3.5.583.583 0 0 0 0 1.167Zm5.304 6.264a1.809 1.809 0 0 1 0 2.554l-.53.612C8.162 17.504-3.464 5.881 1.04 1.088l.67-.583a1.797 1.797 0 0 1 2.524.023c.018.019 1.1 1.423 1.1 1.423a1.808 1.808 0 0 1-.005 2.497l-.675.85a7.456 7.456 0 0 0 4.043 4.051l.854-.68a1.808 1.808 0 0 1 2.498-.003s1.403 1.08 1.421 1.098Zm-.803.849s-1.395-1.074-1.414-1.092a.642.642 0 0 0-.903 0c-.016.016-1.193.953-1.193.953a.583.583 0 0 1-.57.089 8.756 8.756 0 0 1-5.146-5.138.583.583 0 0 1 .084-.583s.938-1.178.954-1.193a.642.642 0 0 0 0-.903C4.46 2.728 3.388 1.33 3.388 1.33a.642.642 0 0 0-.881.023l-.671.583c-3.291 3.958 6.783 13.474 10.251 10.196l.532-.612a.654.654 0 0 0 .05-.909Z"
                                                      />
                                                    </g>
                                                    <defs>
                                                      <clipPath id="a">
                                                        <path
                                                          fill="#fff"
                                                          d="M0 0h14v14H0z"
                                                        />
                                                      </clipPath>
                                                    </defs>
                                                  </svg>
                                                </span>
                                                <p>{item.phoneNumber}</p>
                                              </li>
                                            </ul>
                                            <p>{item.profile}</p>
                                          </div>
                                          <div className="d-flex align-items-center flex-wrap main-button-section">
                                            <button
                                              className="btn btn-outline-primary"
                                              onClick={() =>
                                                handleClinicClick(item)
                                              }
                                            >
                                              {t("book_appointment")}
                                            </button>
                                            {item.clinicType === "GP" && (
                                              <>
                                                <button
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    handleManageAppointment(
                                                      item
                                                    )
                                                  }
                                                >
                                                  {t("manage_appointment")}
                                                </button>
                                                <button
                                                  className="btn btn-outline-primary"
                                                  onClick={() =>
                                                    handleAccount(item)
                                                  }
                                                >
                                                  {t("account")}
                                                </button>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </>
                              )}
                            </div>
                          ) : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      {/* search section start */}
      {step === 1 && (
        <section className="booking-page-section">
          <div className="booking-page-inner-section">
            <div className="container">
              <div className="row position-relative">
                <div className="col-lg-10">
                  <div className="booking-appointment-box">
                    <div className="booking-appointment-inner-box">
                      <div className="booking-appointment-header">
                        <div className="booking-appointment-header-left">
                          <h4>{t("book_appointment_now")}</h4>
                        </div>
                        <div className="booking-appointment-header-right">
                          <div className="booking-appointment-right-items">
                            {serviceOpt.length ? (
                              <Slider {...settings}>
                                {serviceOpt?.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      onClick={() => handleSelectClinic(item)}
                                      className={`${
                                        searchDetails?.practitioner?.value ===
                                        item.value
                                          ? "selected-clinic-chip"
                                          : ""
                                      } popular-clinic-chip`}
                                    >
                                      <p className="tiems-p mb-0">
                                        {item.label}
                                      </p>
                                    </div>
                                  );
                                })}
                              </Slider>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="booking-appointment-body">
                        <div className="booking-appointment-inner-body">
                          <div className="w-100 d-block">
                            <div className="inner-appointment-body d-flex align-items-center w-100">
                              <div className="booking-appointment-body-item ">
                                <div className="book-img-icon">
                                  <img
                                    src="/assets/images/doctors/book-img-icon-img-1.png"
                                    alt=""
                                  />
                                </div>
                                <AsyncCreatableSelect
                                  cacheOptions
                                  value={searchDetails.practitioner}
                                  placeholder="speciality or practitioner name"
                                  defaultOptions={serviceOpt}
                                  onChange={handlePractitionerChange}
                                  loadOptions={promiseOptions}
                                  formatCreateLabel={(value) =>
                                    `search for "${value}"`
                                  }
                                  // onInputChange={(value) => {
                                  //   handleInputChange(value);
                                  // }}
                                />
                              </div>
                              <div className="booking-appointment-body-item">
                                <div className="position-relative">
                                  <div className="book-img-icon">
                                    <img
                                      src="/assets/images/doctors/book-img-icon-img-2.png"
                                      alt=""
                                    />
                                  </div>
                                  <AsyncSelect
                                    cacheOptions
                                    placeholder="suburb"
                                    name="postcode"
                                    onChange={handlePostCodeChange}
                                    loadOptions={promiseAddressOptions}
                                    // onInputChange={(value) => {
                                    //   handleInputChange(value);
                                    // }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <Button
                            onClick={handleSearch}
                            className="ct-search-button"
                          >
                            <img
                              src="/assets/images/doctors/search-w.png"
                              alt=""
                            />
                            {t("search")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* search section end */}
      <DownloadApp app="home" step={step} />
    </>
  );
};

export default Search;
