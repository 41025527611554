import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import AboutPatientForm from "../bookingForm/aboutPatientForm";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDateString, isValidDate } from "../../lib/dateutils";
import Spinner from "../booking/shared/spinner";
import Loader from "../common/Loader";

const Account = () => {
  const _ = require("lodash");
  const { clinicCode } = useParams();
  const { t } = useTranslation();

  const [patientDetails, setPatientDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    streetAddress: "",
    suburb: "",
    postcode: "",
  });
  const [bDate, setBDate] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [patientDOB, setPatientDOB] = useState("");
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [accountListData, setAccountListData] = useState([]);
  const [step, setStep] = useState(1);
  const [emailInvoiceMsg, setEmailInvoiceMsg] = useState("");
  const [msgLoading, setMsgLoading] = useState(0);

  const locale = localStorage.getItem("locale");

  const [emailModalShow, setEmailModalShow] = useState(false);
  const handleEmailModalClose = () => {
    setEmailModalShow(false);
  };

  const onValueChange = (e) => {
    setPatientDetails({
      ...patientDetails,
      [e.target.name]: e.target.value,
    });
  };

  let dateOptions = [];
  dateOptions = _.range(1, 32).map((i) => ({ value: `${i}`, label: `${i}` }));

  const monthOptions = useMemo(
    () => [
      { value: "01", label: t("january") },
      { value: "02", label: t("february") },
      { value: "03", label: t("march") },
      { value: "04", label: t("april") },
      { value: "05", label: t("may") },
      { value: "06", label: t("june") },
      { value: "07", label: t("july") },
      { value: "08", label: t("august") },
      { value: "09", label: t("september") },
      { value: "10", label: t("october") },
      { value: "11", label: t("november") },
      { value: "12", label: t("december") },
    ],
    [t]
  );

  let yearOptions = [];
  yearOptions = _.range(new Date().getFullYear(), 1930 - 1, -1).map((i) => ({
    value: `${i}`,
    label: `${i}`,
  }));

  useEffect(() => {
    // Update patientDOB when bDate is set
    if (bDate?.day !== "" && bDate?.month !== "" && bDate?.year !== "") {
      const mm = monthOptions?.filter((i) => i?.label === bDate?.month);
      if (bDate?.day?.length === 1) {
        setPatientDOB(`0${bDate?.day}/${mm[0]?.value}/${bDate?.year}`);
      } else {
        setPatientDOB(`${bDate?.day}/${mm[0]?.value}/${bDate?.year}`);
      }
    }
  }, [bDate, monthOptions]);

  const handleDateChange = (e, name) => {
    setBDate((prev) => {
      return {
        ...prev,
        [name.name]: name.name === "month" ? e.label : e.value,
      };
    });
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    setValidated(true);
    if (!form.checkValidity() || !isValidDate(patientDOB)) {
      return;
    }

    setStep(2);
    setIsLoading(true);
    fetch(
      `/server/invoice/patient/${clinicCode}/${patientDetails?.firstName}/${
        patientDetails?.lastName
      }/${patientDOB?.split("/").join("")}/${patientDetails?.phoneNumber}`
    )
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setAccountListData(data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("ERROR: ", err);
      });
  };

  const handleEmail = (item, index) => {
    setMsgLoading(index + 1);

    let payload = {
      clinicCode: clinicCode,
      invoiceId: item.invoiceId,
    };

    fetch("/server/invoice/email", {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setMsgLoading(0);
        if (res?.emailResult) {
          setEmailModalShow(true);
          if (Number(res?.emailResult) === 1) {
            setEmailInvoiceMsg(t("invoice_res_1"));
          } else if (Number(res?.emailResult) === -1) {
            setEmailInvoiceMsg(t("invoice_res_2"));
          } else if (Number(res?.emailResult) === -100) {
            setEmailInvoiceMsg(t("invoice_res_3"));
          } else {
            setEmailInvoiceMsg(t("invoice_res_4"));
          }
        } else {
          setMsgLoading(0);
          setEmailInvoiceMsg(res?.errorMessage);
        }
      })
      .catch((err) => {
        setMsgLoading(0);
        console.error("ERROR: ", err);
      });
  };

  const totalPaid = (service) => {
    return service?.reduce((acc, service) => acc + service.paid, 0);
  };

  const totalFee = (service) => {
    return service?.reduce((acc, service) => acc + service.fee, 0);
  };

  return (
    <div className="container footer-stick">
      {step === 1 && (
        <div className="main-manage-appointment-section">
          {/* <div className="d-flex align-items-center page-title-text">
            <Link to={`/${localStorage.getItem("locale")}/`} className="me-3">
              <img
                src="/assets/images/doctors/right-artrow-c.png"
                alt="back_arrow"
                className="title-back-arrow"
              />
            </Link>
            <h3>{t("account")}</h3>
          </div> */}
          <div className="appoi-nav-main back-button-container pt-0 px-0">
            <div className="appoi-nav-inner-main">
              <Link to={`/${locale}/`} className="btn back-button">
                <i className="fas fa-arrow-left filled"></i>
                <span>{t("back")}</span>
              </Link>
            </div>
          </div>
          <div className="mb-4">
            <h3>{t("account")}</h3>
          </div>

          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <AboutPatientForm
              onValueChange={onValueChange}
              bookingDetails={patientDetails}
              bDate={bDate}
              dateOptions={dateOptions}
              monthOptions={monthOptions}
              yearOptions={yearOptions}
              handleDateChange={handleDateChange}
              validated={validated}
              birthdate={patientDOB}
              isMailReq={true}
            />
            <div className="d-flex align-items-center justify-content-center flex-wrap main-manage-appointment-button">
              {/* <Link to={"/"} className="btn ct-button-cancel">
                {t("no")}
              </Link> */}
              <button type="submit" className="btn ct-button-next">
                {t("next")}
              </button>
            </div>
          </Form>
        </div>
      )}

      {isLoading ? (
        <div className="main-loader">
          <Spinner />
        </div>
      ) : (
        step === 2 && (
          <div className="account-list-table-section">
            {/* <div className="d-flex align-items-center page-title-text">
              <Link to={`/${localStorage.getItem("locale")}/`} className="me-3">
                <img
                  src="/assets/images/doctors/right-artrow-c.png"
                  alt="back_arrow"
                  className="title-back-arrow"
                />
              </Link>
              <h3 className="mb-0">{t("account_list")}</h3>
            </div> */}
            <div className="appoi-nav-main back-button-container pt-0 px-0">
              <div className="appoi-nav-inner-main">
                <Link to={`/${locale}/`} className="btn back-button">
                  <i className="fas fa-arrow-left filled"></i>
                  <span>{t("back")}</span>
                </Link>
              </div>
            </div>
            <div className="mb-4">
              <h3>{t("account_list")}</h3>
            </div>
            {accountListData?.length !== 0 ? (
              accountListData?.map((i, index) => (
                <div className="account-list-table" key={index}>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>{t("invoice_id")}</th>
                        <th>{t("date")}</th>
                        <th>{t("provider")}</th>
                        <th className="col-3">{t("service")}</th>
                        <th>{t("mbs_item")}</th>
                        <th>{t("fee")}</th>
                        <th>{t("paid")}</th>
                        {/* {i?.payerId !== 0 ? <th>{t("paid")}</th> : <th></th>} */}
                        {/* {i?.payerId !== 0 && (
                          <th>
                            <button
                              type="button"
                              className="btn btn-outline-info btn-sm border"
                              onClick={() => handleEmail(i, index)}
                            >
                              {Number(msgLoading) === index + 1 ? (
                                <Loader color="primary" size="sm" />
                              ) : (
                                t("email_a_copy")
                              )}
                            </button>
                          </th>
                        )} */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{i?.invoiceId}</td>
                        <td>{formatDateString(i?.invoiceDate)}</td>
                        <td>{i?.doctorName}</td>
                        <td>{i?.services[0]?.itemDescription}</td>
                        <td>
                          {i?.services[0]?.mbsItem !== 0
                            ? i?.services[0]?.mbsItem
                            : ""}
                        </td>
                        {i?.payerId !== 0 ? (
                          <td>${parseFloat(i?.services[0]?.fee).toFixed(2)}</td>
                        ) : (
                          <td>{t("bulk_billed")}</td>
                        )}
                        {i?.payerId !== 0 ? (
                          <td colSpan={2}>
                            ${parseFloat(i?.services[0]?.paid).toFixed(2)}
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                      {i?.services?.length > 1 &&
                        i?.services?.slice(1).map((j, jIndex) => (
                          <tr key={jIndex}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{j?.itemDescription}</td>
                            <td>{j?.mbsItem !== 0 ? j?.mbsItem : ""}</td>
                            {i?.payerId !== 0 && (
                              <td>${parseFloat(j?.fee).toFixed(2)}</td>
                            )}
                            {i?.payerId !== 0 && (
                              <td colSpan={2}>
                                ${parseFloat(j?.paid).toFixed(2)}
                              </td>
                            )}
                          </tr>
                        ))}
                      {i?.payerId !== 0 && (
                        <tr>
                          <td>
                            <button
                              type="button"
                              className="btn btn-outline-info btn-sm border rounded"
                              onClick={() => handleEmail(i, index)}
                            >
                              {Number(msgLoading) === index + 1 ? (
                                <Loader color="primary" size="sm" />
                              ) : (
                                t("email")
                              )}
                            </button>
                          </td>
                          <td colSpan="4" className="text-end fw-bold">
                            {t("total")}
                          </td>
                          <td className="fw-bold">
                            ${parseFloat(totalFee(i?.services)).toFixed(2)}
                          </td>
                          <td colSpan={2} className="fw-bold">
                            ${parseFloat(totalPaid(i?.services)).toFixed(2)}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              ))
            ) : (
              <div className="text-center mb-5 fw-bold no-data-msg">
                {t("account_list_msg")}
              </div>
            )}
          </div>
        )
      )}

      {/* modal starts */}
      {emailModalShow && (
        <Modal
          className="checking-modal-section"
          show={emailModalShow}
          onHide={handleEmailModalClose}
          centered
        >
          <Modal.Body>
            <div className="checking-modal-body">
              <>
                <h4 className="h4 fw-semibold mb-3 text-center">
                  {emailInvoiceMsg}
                </h4>
                <div className="text-center checking-modal-button">
                  <Button variant="primary" onClick={handleEmailModalClose}>
                    {t("close")}
                  </Button>
                </div>
              </>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default Account;
